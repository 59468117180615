import { Box, useMediaQuery } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useEffect, useState } from "react";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    margin: "auto",
    height: 150,
    background: "rgba(65, 144, 205, 0.2);",
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    flexDirection: "column",
    padding: "20px",
    cursor: "normal",
  },

  agreeButton: {
    padding: 10,
    border: "1px solid #333",
    background: "rgba(65, 144, 205, 0.8);",
    width: 200,
    margin: "auto",
    cursor: "pointer",
  },
  termsAndConditions: {
    textDecoration: "underline",
    margin: "auto",
    width: "fit-content",
    cursor: "pointer",
    "& a": {
      color: "black",
    },
  },
}));

const AgreeTermsAndConditions = ({ isFullWidth }) => {
  const [termsAndConditionsAgreed, setTermsAndConditionsAgreed] =
    useState(true);
  const isMobile = useMediaQuery("(min-width:800px)");
  const classes = useStyles({ isFullWidth, isMobile });

  const handleAgree = () => {
    localStorage.setItem("rentArtStuff-T&C", "true");
    setTermsAndConditionsAgreed(true);
  };

  useEffect(() => {
    const agreedToTermsAndConditions = localStorage.getItem("rentArtStuff-T&C");

    if (agreedToTermsAndConditions !== "true") {
      setTermsAndConditionsAgreed(false);
    } else {
      setTermsAndConditionsAgreed(true);
    }
  }, [termsAndConditionsAgreed]);

  return (
    <Box>
      {!termsAndConditionsAgreed && (
        <Box className={classes.container}>
          <Box>
            I agree to the RentArtStuff.com{" "}
            <Box className={classes.termsAndConditions}>
              <a
                href="/terms-and-conditions/terms-and-conditions.pdf"
                target="_blank"
                title="RentArtStuff.com Terms & Conditions"
              >
                Terms & Conditions
              </a>
            </Box>
          </Box>

          <Box onClick={handleAgree} className={classes.agreeButton}>
            I Agree
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default AgreeTermsAndConditions;
