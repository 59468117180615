import { atom, useRecoilState } from "recoil";

const listingLocationState = atom({
  key: "listingLocationState",
  default: "",
});

const useStep2State = () => {
  const [listingLocation, setListingLocation] =
    useRecoilState(listingLocationState);

  const handleListingLocationChange = (value) => {
    setListingLocation(value);
  };

  const clearStep2 = () => {
    setListingLocation("");
  };

  return {
    listingLocation,
    setListingLocation,
    handleListingLocationChange,
    clearStep2,
  };
};

export { useStep2State };
