import { ENDPOINTS } from "../endpoints";
import { createApi } from "../setup";

const useUsersApi = () => {
  const editUser = async (data) => {
    const API = await createApi();
    const user = await API.post(ENDPOINTS.USERS_UPDATE, data).then((resp) => {
      return resp.data;
    });
    return user;
  };

  const editImage = async (imageUrl) => {
    const API = await createApi();
    const user = await API.post(ENDPOINTS.USERS_UPDATE_IMAGE, imageUrl).then(
      (resp) => {
        return resp.data;
      }
    );
    return user;
  };

  const loginToBackend = async () => {
    const API = await createApi();
    await API.post(ENDPOINTS.USERS_LOGIN).then((resp) => {
      localStorage.setItem("user_id", resp.data.internal_user_id);
    }).catch(error => console.error(error));
  };

  return {
    editUser,
    editImage,
    loginToBackend,
  };
};

export default useUsersApi;
