import { useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/material";
import ImageUploader from "react-images-upload";
import { createListingStates } from "../../../state/CustomHooks";

const useStyles = makeStyles((theme) => ({
  container: {},
}));
const CreateListingImages = (props) => {
  const { error } = props;
  const classes = useStyles();
  const { useStep4State } = createListingStates;
  const { listingImages, handleListingImagesChange } = useStep4State();

  useEffect(() => {
    onDrop(listingImages);
  });

  const onDrop = (picture) => {
    handleListingImagesChange(picture);
  };

  return (
    <Box className={classes.container}>
      <Box component="h3" textAlign="left">
        Upload Pictures{" "}
        <span style={{ color: "red" }}>
          {error ? "At least 1 image is required." : ""}
        </span>
      </Box>
      <ImageUploader
        fileContainerStyle={{ backgroundColor: "#ccc" }}
        withIcon={true}
        buttonText="Choose images"
        onChange={onDrop}
        withPreview
        label={"Max File Size 5MB."}
        maxFileSize={5242880}
      />
      {listingImages.length > 0 && (
        <Box>
          <Box component="h3">Selected Images</Box>
          {listingImages.map((image, index) => (
            <Box textAlign="left" p={1} key={image.name + index}>
              {index + 1}. {image.name}
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
};

export default CreateListingImages;
