import React, { useEffect, useState } from 'react';
import InvoiceHeader from './components/invoiceHeader';
import InvoiceContent from './components/invoiceContent';
import './style.css';

function InvoicePage() {
    const [rental, setRental] = useState(null);
    const [invoiceNumber, setInvoiceNumber] = useState("001");

    useEffect(() => {
        const tmp = localStorage.getItem("curr_rental");
        setInvoiceNumber(Math.floor(Math.random() * 1000000).toString().padStart(6, '0'));
        setRental(JSON.parse(tmp));
    }, []);

    return (
        <form className='invoice-container'>
            <InvoiceHeader rental={rental} invoiceNumber={invoiceNumber} />
            <InvoiceContent rental={rental} invoiceNumber={invoiceNumber} />
        </form>
    )
}

export default InvoicePage;