import React, { useCallback, useState } from "react";
import Slider from "@material-ui/core/Slider";
import Cropper from "react-easy-crop";
import getCroppedImg, { blobUrlToFile } from "./util";
import { useImageUploadApi } from "../../../../api";
import useUsersApi from "../../../../api/users";
import {
  Container,
  ControlLabel,
  Controls,
  CropButton,
  CropContainer,
  CropImageContainer,
  CancelButton,
} from "./styled";

const SimpleImageCropper = ({
  image,
  imageName,
  imageType,
  handleClose,
  user,
  refresh,
  isMobile,
}) => {
  const { uploadFile } = useImageUploadApi();
  const { editImage } = useUsersApi();
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [loading, setLoading] = useState(false);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        image,
        croppedAreaPixels,
        rotation
      );
      const file = await blobUrlToFile(croppedImage, imageName, imageType);
      setCroppedImage(croppedImage);
      await handleSaveImage(file);
    } catch (e) {
      console.error(e);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [croppedAreaPixels, rotation, image]);

  const handleSaveImage = async (file) => {
    setLoading(true);
    setCroppedImage(null);
    const url = await uploadFile(file, "listingId", user);
    const data = { image_url: url };
    handleClose();
    await editImage(data);
    await refresh();
    setLoading(false);
  };

  const handleCancel = useCallback(
    () => handleClose(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <div>
      {!loading && (
        <Container
          style={{
            display: image === null || croppedImage !== null ? "none" : "block",
          }}
        >
          <CropContainer isMobile={isMobile}>
            <Cropper
              image={image}
              crop={crop}
              rotation={rotation}
              zoom={zoom}
              zoomSpeed={4}
              maxZoom={3}
              zoomWithScroll={true}
              showGrid={true}
              aspect={4 / 3}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
              onRotationChange={setRotation}
            />
          </CropContainer>
          <Controls isMobile={isMobile}>
            <ControlLabel>
              Rotation {rotation}/360&#176;
              <Slider
                value={rotation}
                min={0}
                max={360}
                step={1}
                aria-labelledby="rotate"
                onChange={(e, rotation) => setRotation(rotation)}
                className="range"
                style={{ padding: 0 }}
              />
            </ControlLabel>
            <ControlLabel>
              Zoom {zoom.toFixed(1)}/3
              <Slider
                value={zoom}
                min={1}
                max={3}
                step={0.1}
                aria-labelledby="zoom"
                onChange={(e, zoom) => setZoom(zoom)}
                className="range"
                style={{ padding: 0 }}
              />
            </ControlLabel>
          </Controls>
        </Container>
      )}
      {!loading && image && (
        <>
          <CropButton
            style={{
              display:
                image === null || croppedImage !== null ? "none" : "block",
            }}
            onClick={showCroppedImage}
          >
            Crop
          </CropButton>
          <CancelButton onClick={handleCancel}>Cancel</CancelButton>
        </>
      )}
      <CropImageContainer>
        {loading && <div>Updating your profile image... Please wait.</div>}
      </CropImageContainer>
    </div>
  );
};

export default SimpleImageCropper;
