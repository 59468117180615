import { Box } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import PageWrapper from "../../components/PageWrapper";
import MidSection from "./MidSection";
import { colors } from "theme";

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: colors.lightYellow,
    marginTop: 150,
  },
  title: {
    fontSize: 20,
  },
}));
const AboutPage = () => {
  const classes = useStyles();
  return (
    <PageWrapper isNavBarShowing>
      <Box className={classes.container}>
        {/* <Box className={classes.title}>How it works</Box> */}
        <MidSection />
      </Box>
    </PageWrapper>
  );
};

export default AboutPage;
