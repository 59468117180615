import { ENDPOINTS } from "../endpoints";
import { createApi } from "../setup";

const useImageAssetsApi = () => {
  const removeImageAsset = async (assetId) => {
    const API = await createApi();
    await API.delete(`${ENDPOINTS.RENT_ART_ASSETS}/${assetId}`)
      .then((resp) => {
        return "Success";
      })
      .catch((e) => {
        return "Error";
      });
  };

  return {
    removeImageAsset,
  };
};

export default useImageAssetsApi;
