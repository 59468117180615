import React, { useState } from "react";
import { Box, Collapse } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";
import { colors } from "theme";

const useStyles = makeStyles((theme) => ({
  container: {
    color: "black",
    textAlign: "left",
    borderTop: "1px solid black",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    position: "relative",
    padding: 20,
    cursor: "pointer",
  },
  title: {
    fontWeight: "bold",
  },
  answer: {
    marginTop: 10,
    padding: 10,
    borderTop: `1px solid black`,
    backgroundColor: colors.lightGreen,
  },
  icon: {
    cursor: "pointer",
    position: "absolute",
    top: 10,
    right: 10,
  },
  textGroup: {
    width: "100%",
  },
}));

const Question = ({ question, index }) => {
  const [open, setOpen] = useState(false);
  const classes = useStyles({ open });

  return (
    <Box className={classes.container} onClick={() => setOpen((open) => !open)}>
      <Box className={classes.textGroup}>
        <Box className={classes.title}>
          {index + 1}. {question.title}
        </Box>
        <Collapse in={open}>
          <Box className={classes.answer}>{question.answer}</Box>
        </Collapse>
      </Box>
      <Box className={classes.icon}>
        {open && <Box component="img" src="/icons/up-arrow.svg" width="30px" />}
        {!open && (
          <Box component="img" src="icons/down-arrow.svg" width="30px" />
        )}
      </Box>
    </Box>
  );
};

export default Question;
