import { ENDPOINTS } from "../endpoints";
import { createApi } from "../setup";

const useSavedListingsApi = () => {
  const saveListing = async (listingId) => {
    const API = await createApi();

    const savedListing = await API.post(
      `${ENDPOINTS.SAVED_LISTINGS}/${listingId}`
    )
      .then((resp) => {
        return resp.data;
      })
      .catch((e) => {
        return "error";
      });
    return savedListing;
  };

  const removeSavedListing = async (listingId) => {
    const API = await createApi();
    await API.delete(`${ENDPOINTS.SAVED_LISTINGS}/${listingId}`)
      .then((resp) => {
        return resp.data;
      })
      .catch((e) => {
        return "error";
      });
  };

  const toggleSaved = async (listingId) => {
    const API = await createApi();
    await API.post(`${ENDPOINTS.TOGGLE_SAVED}/${listingId}`)
      .then((resp) => {
        return resp;
      })
      .catch((e) => {
        console.log("error", e);
        return e;
      });
  };

  return {
    saveListing,
    removeSavedListing,
    toggleSaved,
  };
};

export default useSavedListingsApi;
