import React from "react";
import { Box } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  container: {},
  title: {
    fontWeight: "bold",
    marginBottom: 5,
  },
  content: {
    marginBottom: 15,
  },
}));

const DetailLine = (props) => {
  const classes = useStyles();
  const { title, content } = props;
  return (
    <Box className={classes.container}>
      <Box className={classes.title}>{title}</Box>
      <Box className={classes.content}>{content}</Box>
    </Box>
  );
};

export default DetailLine;
