import React from "react";
import { Box, Select, FormControl, MenuItem, InputLabel } from "@mui/material";

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  container: {
    margin: 10,
    width: (props) => (props.halfWidth ? "50%" : ""),
  },
  formControl: {
    width: "100%",
    textAlign: "left",
  },
}));

const CommonSelect = (props) => {
  const {
    options,
    handleChange,
    defaultValue,
    name,
    currentValue = [],
    halfWidth,
    multiple,
    customMenuItem,
  } = props;
  const classes = useStyles({ halfWidth });
  const handleChangeMultiple = (event) => {
    const { value } = event.target;
    const s = [];
    for (let i = 0, l = value.length; i < l; i += 1) {
      if (value[i]) {
        s.push(value[i]);
      }
    }
    handleChange(s);
  };
  return (
    <Box className={classes.container}>
      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel id="demo-simple-select-outlined-label">{name}</InputLabel>
        {!multiple && !customMenuItem && (
          <Select
            defaultValue={defaultValue}
            onChange={handleChange}
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            variant="outlined"
            fullWidth
            label={name}
          >
            {options.map((option, index) => (
              <MenuItem value={option.value || option} key={name + option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        )}
        {!multiple && customMenuItem && (
          <Select
            defaultValue={defaultValue}
            onChange={handleChange}
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            variant="outlined"
            fullWidth
            label={name}
          >
            {options.map((option, index) => (
              <MenuItem value={option} key={name + option}>
                {option.component}
              </MenuItem>
            ))}
          </Select>
        )}
        {multiple && (
          <Select
            value={currentValue}
            defaultValue={defaultValue}
            onChange={handleChangeMultiple}
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            variant="outlined"
            fullWidth
            multiple
            label={name}
          >
            {options.map((option, index) => (
              <MenuItem value={option} key={name + option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        )}
      </FormControl>
    </Box>
  );
};

export default CommonSelect;
