import { Toolbar, Button, AppBar, Box, useMediaQuery } from "@mui/material";
import { PUBLIC_NAV_ITEMS } from "./static";
import { useAuth0 } from "@auth0/auth0-react";
import LoginButton from "../Auth0/LoginButton";
import UserProfile from "../Auth0/UserProfile";
import { Link } from "@reach/router";
import { navigate } from "@reach/router";
import styled from "@emotion/styled";
// import UnreadMessage from "components/Auth0/UnreadMessage";
// import Balance from "components/Auth0/Balance";

type LogoWrapperProps = {
  showLogo: boolean;
};

const LogoWrapper = styled.div<LogoWrapperProps>`
  height: 80px;
  transition: overflow 0.3s ease;

  overflow: ${(props) => (props.showLogo ? "" : "hidden")};
`;

type NavBarContainerProps = {
  showLogo: boolean;
};

const NavBarContainer = ({ showLogo }: NavBarContainerProps) => {
  const { isAuthenticated } = useAuth0();
  const isMobile = useMediaQuery("(max-width:800px)");

  return (
    <AppBar>
      <Toolbar>
        <LogoWrapper showLogo={showLogo}>
          {!isMobile && <Button variant="logo" onClick={() => navigate("/")} />}
        </LogoWrapper>
        <Box
          display="flex"
          justifyContent="center"
          flex="auto"
          className="center-links"
          sx={{
            a: {
              "&:not(:last-of-type)::after": {
                marginRight: ".8rem",
                marginLeft: ".8rem",
                content: '"|"',
              },
            },
          }}
        >
          {PUBLIC_NAV_ITEMS.map((item) => (
            <Link key={item.title} to={`${item.link}`}>
              {item.title}
            </Link>
          ))}
        </Box>
        {/* <UnreadMessage /> */}
        {/* <Balance></Balance> */}
        <Box width="10rem">
          {isAuthenticated ? <UserProfile /> : <LoginButton />}
        </Box>
      </Toolbar>

      {/* Blue "border" for bottom of toolbar */}
      <Box display="flex" height="2px" width="100%">
        {!isMobile && (
          <>
            <Box
              sx={{ backgroundColor: "rentArt.blue" }}
              height="100%"
              width="2rem"
            />
            <Box
              sx={{
                background: `linear-gradient(90deg, #4190cd, 20%, transparent, 60%, #4190cd);`,
              }}
              width="22rem"
            />
          </>
        )}
        <Box
          sx={{ backgroundColor: "rentArt.blue" }}
          flex="auto"
          height="100%"
        />
      </Box>
    </AppBar>
  );
};

export default NavBarContainer;
