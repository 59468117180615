export const QUESTIONS = [
  {
    title: "What if someone breaks my stuff?",
    answer:
      "They break it, they buy it. In the event that a renter breaks something belonging to another rentartstuff.com user, their credit card will be charged for the full amount of the item.",
  },
  {
    title: "What if it's only partially broken?",
    answer: "Then they are are charged the full price of repair.",
  },
  {
    title: "What if I rent something, but it's broken when I pick it up?",
    answer: "Just bring it back and let the provider know.",
  },
  {
    title: "What if the provider says I broke it?",
    answer:
      "Then you have to register a dispute with the provider. In the event of a problem or miscommunication, a user or provider can open a dispute ticket.",
  },
  {
    title: "What can I rent?",
    answer:
      "You can provide or use any type of equipment or space related to creative work.",
  },
  {
    title:
      "Why would I rent from somebody down the street instead of buying the equipment outright? Or, why wouldn't I rent from a reputable company?",
    answer:
      "The world of creative work has many unique, specialized tools. Some of these tools are very expensive, and, even if the price isn't unreasonable, many of these tools only need to be used once or just a few times per year. It doesn't make a lot of sense to spend hundreds or thousands of dollars on a piece of equipment that's only going...",
  },
];
