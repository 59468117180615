import React, { useState } from "react";
import { Box, Modal } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import AddImageUploader from "./AddImageUploader";
import { useEditListingState } from "../../../state/editListingStates";
import { useImageUploadApi } from "../../../api";
import CommonButton from "../../../components/CommonButton";
import useSWR from "swr";
import { ENDPOINTS } from "../../../api/endpoints";

const useStyles = makeStyles((theme) => ({
  container: {
    borderLeft: "1px solid black",
    borderRight: "1px solid black",
    borderBottom: "1px solid black",
    minHeight: "100px",
    textAlign: "center",
    padding: 10,
    backgroundColor: theme.palette.primary.mainBackgroundColor,
  },
  modal: {
    overflow: "scroll",
  },
  buttonGroup: {
    display: "flex",
    width: "fit-content",
    padding: 10,
    margin: "auto",
  },
  button: {
    margin: 5,
  },
}));

const AddImageModal = (props) => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const { uploadFile, saveImageToListing } = useImageUploadApi();
  const { data: user } = useSWR(ENDPOINTS.USERS_ME);
  const { open, handleClose, listing, refresh } = props;
  const classes = useStyles();
  const { assets, handleAssetChange } = useEditListingState();

  const savingAssetToListing = async (listingId, reference) => {
    await saveImageToListing(listingId, reference)
      .then((resp) => {
        setLoading(false);
        setSuccess(true);
        setError(false);
        refresh();
      })
      .catch(() => {
        setSuccess(false);
        setLoading(false);
        setError(true);
        refresh();
      });
  };

  const addAssets = async (file, listingId) => {
    setError(false);
    setSuccess(false);
    setLoading(true);
    const reference = await uploadFile(file, listingId, user);
    savingAssetToListing(listingId, reference);
  };

  const handleUpload = () => {
    assets.forEach((file) => {
      addAssets(file, listing.id);
    });
  };
  return (
    <Modal
      open={open}
      onClose={() => {
        setSuccess(false);
        setLoading(false);
        setError(false);
        handleAssetChange([]);
        handleClose();
      }}
      className={classes.modal}
    >
      <Box className={classes.container}>
        {!success && !loading && <AddImageUploader />}
        {!success && !loading && (
          <Box className={classes.buttonGroup}>
            {assets.length > 0 && (
              <Box className={classes.button}>
                <CommonButton
                  text="Upload"
                  isUppercase
                  handleClick={() => handleUpload()}
                />
              </Box>
            )}
            <Box className={classes.button}>
              <CommonButton
                text="Cancel"
                isUppercase
                buttonVariant="normal"
                handleClick={() => {
                  handleAssetChange([]);
                  handleClose();
                }}
              />
            </Box>
          </Box>
        )}
        {success && (
          <Box className={classes.buttonGroup}>
            <Box className={classes.button}>
              <CommonButton
                text="Ok"
                isUppercase
                buttonVariant="normal"
                handleClick={() => handleClose()}
              />
            </Box>
          </Box>
        )}
        {loading && <Box>Uploading now...</Box>}
        {error && <Box>Something went wrong.</Box>}
        {success && <Box>Success!</Box>}
      </Box>
    </Modal>
  );
};

export default AddImageModal;
