import { createTheme } from "@mui/material/styles";

export const colors = {
  // red: "#CC3333",
  disabledBackground: "#787575",
  disabledText: "#b5b0b0",
  lightBlue: "#69aca9",
  lightYellow: "#FFF",
  orange: "orange",
  // blue: "#4671a2",
  blueHover: "#6280a3",
  lightGreen: "#b7cb59",
  // green: "#81bb48",
  // purple: "#8a63a9",
  pink: "#bf5b7b",
  offWhite: "#f5f5f5",
  coolBlue:
    "linear-gradient(0deg, rgba(34,193,195,1) 0%, rgba(144,191,189,1) 100%)",

  coolBlueHover: "#3696f5",
  oldBlue: "#0080ff",
  background: "#FFFFF0",
  blue: "#4190cd",
  fadedBlue: "rgba(65, 144, 205, 0.2)",
  purple: "#bb3cea",
  red: "#ef694d",
  green: "#85d66b",

  navyBlue: "#003b73",
  blueGrotto: "#60A3D9",
  deepRed: "#bd081c",
};

const lightTheme = createTheme({
  palette: {
    background: {
      default: "#FFFFFF",
    },
    primary: {
      main: "#4190cd",
      light: "#b88293",
    },
    rentArt: {
      red: colors.red,
      orange: colors.orange,
      yellow: colors.lightYellow,
      green: colors.green,
      blue: colors.blue,
      purple: colors.purple,
      pink: colors.pink,
    },
  },
});

let theme = lightTheme;

theme = createTheme(theme, {
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        p: {
          margin: 0,
        },
        a: {
          textDecoration: "none",
        },
      },
    },
    MuiAppBar: {
      defaultProps: {
        elevation: 0,
      },
      styleOverrides: {
        root: {
          backgroundColor: "white",
        },
      },
    },
    MuiButton: {
      defaultProps: {
        variant: "contained",
        disableRipple: true,
      },
      variants: [
        {
          props: { variant: "logo" },
          style: {
            minHeight: "150px",
            minWidth: "200px",
            marginBottom: "-71px",
            zIndex: 10,
            backgroundImage: 'url("/art-assets/logo.png")',
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            marginLeft: "3.5rem",
            "@media (max-width: 1000px)": {
              minHeight: "100px",
              minWidth: "160px",
              marginBottom: "-48px",
              marginLeft: "0",
            },
          },
        },
        {
          props: { variant: "image" },
          style: {
            minHeight: "3rem",
            minWidth: "3rem",
            padding: "0",
            backgroundColor: theme.palette.rentArt.pink,
          },
        },
      ],
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          height: "80px",
          alignItems: "flex-end",
          textTransform: "uppercase",
          fontSize: "1.8rem",
          ".center-links :nth-of-type(1)": {
            color: theme.palette.rentArt.pink,
          },
          ".center-links :nth-of-type(2)": {
            color: theme.palette.rentArt.blue,
          },
          ".center-links :nth-of-type(3)": {
            color: theme.palette.rentArt.green,
          },
          ".center-links nth-of-type(4)": {
            color: theme.palette.rentArt.purple,
          },
          "@media (max-width: 800px)": {
            alignItems: "center",
            height: "60px",
            fontSize: "0.8rem",
            paddingTop: "0.5rem",
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "& fieldset": {
            borderRadius: 0,
            border: `3px solid ${theme.palette.rentArt.blue}`,
          },
        },
      },
    },
  },
});

export default theme;
