import React, { useState } from 'react'
import { Box, useMediaQuery } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { colors } from '../../theme'

const useStyles = makeStyles(theme => ({
  container: {
    marginLeft: props => (props.isMobile ? '' : 20),
    marginRight: props => (props.isMobile ? '' : 100),
    background: colors.offWhite,
    height: 'fit-content',
    padding: 25
  },
  currentImageBox: {
    marginBottom: 10
  },
  subImageGroup: {
    display: 'flex',
    flexWrap: 'wrap',
    borderTop: '1px solid #ccc',
    // borderBottom: "1px solid black",
    padding: 5
  },
  subImage: {
    cursor: 'pointer',
    margin: 5
  },
  subImageSelected: {
    margin: 5,

    width: 'fit-content',
    height: 'fit-content',
    position: 'relative'
  },
  overlay: {
    position: 'absolute',
    backgroundColor: 'rgba(255,255,255,0.3)',
    height: '100%',
    width: '100%',
    top: 0,
    bottom: 0
  }
}))

const ImageSection = props => {
  const { listing } = props
  const images = listing.assets
  const [currentImage, setCurrentImage] = useState(images[0]?.asset_uri)
  const isMobile = useMediaQuery('(max-width:800px)')
  const classes = useStyles({ isMobile })

  return (
    <Box className={classes.container}>
      <Box className={classes.currentImageBox}>
        <Box
          component='img'
          src={currentImage}
          maxWidth={'100%'}
          maxHeight={400}
        />
      </Box>
      <Box className={classes.subImageGroup}>
        {images.length > 0 &&
          images.map((image, index) => (
            <Box
              key={index}
              onClick={() => setCurrentImage(image.asset_uri)}
              className={
                currentImage === image.asset_uri
                  ? classes.subImageSelected
                  : classes.subImage
              }
            >
              {currentImage === image.asset_uri && (
                <Box className={classes.overlay} />
              )}
              <Box
                component='img'
                src={image.asset_uri}
                maxWidth={60}
                maxHeight={60}
              />
            </Box>
          ))}
      </Box>
      {/* <Sliders images={images} /> */}
    </Box>
  )
}

export default ImageSection
