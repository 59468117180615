import React, { useState } from 'react'
import useSavedListingsApi from '../../api/saved-listings'

import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import Divider from '@mui/material/Divider'
import ListItemText from '@mui/material/ListItemText'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import Avatar from '@mui/material/Avatar'
import Typography from '@mui/material/Typography'

import makeStyles from '@mui/styles/makeStyles'
import { colors } from '../../theme'
import { useAuth0 } from '@auth0/auth0-react'

import useSWR from 'swr'
import { ENDPOINTS } from '../../api/endpoints'
import { Box, useMediaQuery } from '@mui/material'
import {
  CalendarToday,
  CalendarTodayOutlined,
  CalendarViewDay,
  Comment,
  CommentSharp,
  Label
} from '@material-ui/icons'
import StarRatings from 'react-star-ratings'
import {
  CalendarViewMonth,
  LockClockOutlined,
  PunchClock
} from '@mui/icons-material'
import { Clock } from '@material-ui/pickers/views/Clock/Clock'

const useStyles = makeStyles(theme => ({
  container: {
    textAlign: 'left',
    marginTop: 30
  },
  caption: {
    textAlign: 'left',
    fontSize: 24,
    fontWeight: 'bold'
  },
  reviewMark: {
    fontSize: 16,
    fontWeight: 'bold'
  },
  title: {
    fontWeight: 'bold',
    fontSize: 18,
    marginTop: 8,
    marginBottom: 8
  },

  datatime: {
    fontSize: 12,
    color: 'grey',
    // fontWeight: 'w300'
    paddingTop: 6
  },
  price: {
    fontSize: 25,
    fontWeight: 'bold',
    marginBottom: 10,
    color: 'green'
  },

  reviewFont: {
    fontSize: 18,
    fontWeight: 'bold',
    color: 'black'
  },
  text: {
    marginBottom: 10
  },
  span: {
    fontWeight: 'bold'
  },
  buttonGroup: {
    display: 'flex'
  },
  button: {
    padding: 5
  },
  hr: {
    borderTop: `1px solid ${colors.offWhite}`
  }
}))

const convertDateFormat = datetime => {
  const today = new Date(datetime)
  const options = { month: 'long', day: 'numeric', year: 'numeric' }
  const formattedDate = today.toLocaleDateString('en-US', options)
  return formattedDate
}

const ReviewSection = props => {
  const { listing } = props
  const isMobile = useMediaQuery('(max-width:800px)')
  const classes = useStyles({ isMobile })

  const reviewItems = listing.reviews.map((item, index) => (
    <div key={index}>
      {index > 0 && <Divider variant='inset' component='li' />}
      <ListItem alignItems='flex-start'>
        <ListItemAvatar>
          {/* <Avatar alt='Remy Sharp' src='/static/images/avatar/1.jpg' /> */}
          <CommentSharp style={{ fontSize: 30 }}></CommentSharp>
        </ListItemAvatar>
        <ListItemText
          primary={
            <React.Fragment>
              <StarRatings
                rating={listing.rating}
                starRatedColor='orange'
                // changeRating={this.changeRating}
                numberOfStars={5}
                name='rating'
                starDimension='24px'
                starSpacing='3px'
              />
              <span className={classes.reviewMark}>
                &nbsp;({item.review_rating})
              </span>
              <p className={classes.title}>{item.title} </p>
            </React.Fragment>
          }
          secondary={
            <React.Fragment>
              <Typography
                sx={{ display: 'inline' }}
                component='span'
                variant='body2'
                color='text.primary'
              >
                {item.review_message}
              </Typography>
              {/* {" — I'll be in your neighborhood doing errands this…"} */}
              <p className={classes.datatime}>
                <CalendarTodayOutlined fontSize='small' />
                &nbsp;&nbsp;{convertDateFormat(item.created_at)}
              </p>
            </React.Fragment>
          }
        />
      </ListItem>
    </div>
  ))

  return (
    <>
      <p className={classes.caption}>Reviews ({listing.reviews.length})</p>
      <Divider></Divider>
      <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
        {reviewItems}
      </List>
    </>
  )
}

export default ReviewSection
