import React, { useState } from "react";
import {
  Box,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useEditListingState } from "../../../state/editListingStates";
import CommonButton from "../../../components/CommonButton";
import useListingsApi from "../../../api/listings";
import { navigate } from "@reach/router";

const useStyles = makeStyles((theme) => ({
  container: {
    borderLeft: "1px solid black",
    borderRight: "1px solid black",
    borderBottom: "1px solid black",
    minHeight: "100px",
    backgroundColor: theme.palette.primary.mainBackgroundColor,
  },
  modal: {
    overflow: "scroll",
  },
  table: {
    overflow: "scroll",
    backgroundColor: "#bbb",
  },
  tableCell: {
    color: "#222",
  },
  buttonGroup: {
    display: "flex",
    justifyContent: "center",
    paddingBottom: 50,
  },
  button: {
    margin: 5,
  },
  isMissingDataButton: {
    margin: "auto",
    marginBottom: 50,
  },
  headTitle: {
    fontWeight: "bold",
    fontSize: 20,
  },
  message: {
    textAlign: "center",
    fontWeight: "bold",
    fontSize: 20,
  },
}));

const PreviewSaveModal = (props) => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [isMissingData, setIsMissingData] = useState(false);
  const { open, listing, handleClose, refresh } = props;
  const {
    //vars
    name,
    description,
    price,
    timeUnit,
    minTime,
    maxTime,
    primaryCategory,
    secondaryCategories,
    exchangeMethod,
    availability,
    type,
    notes,
    collateral,
    tags,
    city,
    priceDescription,
  } = useEditListingState();
  const { updateListing } = useListingsApi();
  const classes = useStyles();
  //===========================
  const dataIsComplete = (data) => {
    const requiredFields = [
      "name",
      "description",
      "location_city",
      "unit_price",
      "unit_time",
      "type",
      "primary_category",
    ];
    const results = [];
    requiredFields.forEach((field) => {
      if (data[field] === "" || data[field] === undefined) {
        results.push("error");
      }
    });
    const foundError = results.find((result) => result !== undefined);
    if (foundError) {
      return false;
    } else {
      return true;
    }
  };

  const saveChanges = async () => {
    const data = {
      listing_id: listing.id,
      name,
      description,
      location_city: city,
      unit_price: price,
      unit_time: timeUnit,
      price_description: timeUnit === "Other" ? priceDescription : "",
      max_time: timeUnit !== "Other" ? maxTime : "",
      min_time: timeUnit !== "Other" ? minTime : "",
      primary_category: primaryCategory,
      sub_categories: secondaryCategories,
      is_currently_available: availability === "Available" ? true : false, // need boolean value
      type: type,
      notes: notes,
      exchange_method: exchangeMethod,
      tags: tags,
      collateral,
    };
    if (dataIsComplete(data)) {
      setLoading(true);
      await updateListing(listing.id, data)
        .then((resp) => {
          setLoading(false);
          setSuccess(true);
          setError(false);
          refresh();
        })
        .catch(() => {
          setSuccess(false);
          setLoading(false);
          setError(true);
          refresh();
        });
    } else {
      setIsMissingData(true);
    }
  };

  const closeModal = () => {
    setSuccess(false);
    setLoading(false);
    setError(false);
    refresh();
    handleClose();
    navigate("/dashboard");
  };

  //============================

  const joiner = (val) => val.join(", ");

  const secondaryCategoryRow = {
    name: "Secondary Categories",
    newValue: joiner(secondaryCategories),
  };

  const tagsRow = {
    name: "Tags",
    newValue: joiner(tags),
  };

  function createData(name, oldValue, newValue) {
    return { name, newValue };
  }

  const rows = [
    createData("Name*", listing.name, name),
    createData("Description*", listing.description, description),
    createData("Price (USD)*", listing.unit_price, price),
    createData("Time Increment*", listing.unit_time, timeUnit),
    timeUnit === "Other" &&
      createData(
        "Price Description*",
        listing.price_description,
        priceDescription
      ),
    timeUnit !== "Other" && createData("Min Time*", listing.min_time, minTime),
    timeUnit !== "Other" && createData("Max Time*", listing.max_time, maxTime),
    createData("Primary Category*", listing.primary_category, primaryCategory),
    secondaryCategoryRow,
    createData("Exchange Method*", listing.exchange_method, exchangeMethod),
    createData(
      "Availability*",
      listing.is_currently_available ? "Available" : "Not Available",
      availability
    ),
    createData("Type*", listing.type, type),
    createData("Notes", listing.notes, notes),
    tagsRow,
    createData("Collateral", listing.collateral, collateral),
    createData("City*", listing.location_city, city),
  ];

  const filteredRows = rows.filter((row) => row !== false);

  return (
    <Modal open={open} onClose={handleClose} className={classes.modal}>
      <Box className={classes.container}>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell className={classes.headTitle}>Info</TableCell>
                <TableCell align="left" className={classes.headTitle}>
                  Edited
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredRows.map((row) => (
                <TableRow key={row.name}>
                  <TableCell
                    component="th"
                    scope="row"
                    width="30%"
                    className={classes.tableCell}
                  >
                    {row.name}
                  </TableCell>
                  <TableCell
                    align="left"
                    width="70%"
                    className={classes.tableCell}
                  >
                    {row.newValue}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {!success && !loading && !isMissingData && (
          <Box className={classes.buttonGroup}>
            <Box className={classes.button}>
              <CommonButton
                text="Save Changes"
                isUppercase
                buttonVariant="coolBlue"
                handleClick={() => saveChanges()}
              />
            </Box>
            <Box className={classes.button}>
              <CommonButton
                text="Cancel"
                isUppercase
                handleClick={() => {
                  closeModal();
                }}
              />
            </Box>
          </Box>
        )}
        {isMissingData && (
          <Box display="flex" flexDirection="column" textAlign="center">
            <Box m={1} mb={2}>
              You are missing some required fields, please go back and complete
              the fields marked with an asterisk (*).
            </Box>
            <Box className={classes.isMissingDataButton}>
              <CommonButton
                text="Ok"
                isUppercase
                buttonVariant="coolBlue"
                handleClick={() => {
                  closeModal();
                }}
              />
            </Box>
          </Box>
        )}
        {loading && <Box className={classes.message}>Uploading now...</Box>}
        {error && <Box className={classes.message}>Something went wrong.</Box>}
        {success && <Box className={classes.message}>Success!</Box>}
        {success && !loading && !error && (
          <Box className={classes.buttonGroup}>
            <Box className={classes.button}>
              <CommonButton
                text="OK"
                isUppercase
                buttonVariant="normal"
                handleClick={() => closeModal()}
              />
            </Box>
          </Box>
        )}
      </Box>
    </Modal>
  );
};

export default PreviewSaveModal;
