import React from "react";
import styled from "@emotion/styled";
import useSWR from "swr";
import { ENDPOINTS } from "../../../api/endpoints";
import Requests from "./Requests";
import Rentals from "./Rentals";

const RentalsContainer = styled.div`
  padding: 30px;
  width: 100%;
`;

const SectionHeader = styled.div`
  font-size: 28px;
  text-align: left;
  margin: 10px;
  border-bottom: 1px solid #ddd;
`;

const RentalManagement = ({ isMobile }) => {
  const { data: me } = useSWR(ENDPOINTS.USERS_ME);

  return (
    <RentalsContainer>
      <SectionHeader>Requests</SectionHeader>
      <Requests user={me} isMobile={isMobile} />
      <SectionHeader>Rentals</SectionHeader>
      <Rentals user={me} isMobile={isMobile} />
    </RentalsContainer>
  );
};

export default RentalManagement;
