import styled from "@emotion/styled";

export const ProfileContainer = styled.div`
  padding: 30px;
  margin-inline: ${(props) => (props.isMobile ? "auto" : "")};
  width: ${(props) => (props.isMobile ? "100%" : "500px")};
`;

export const ImageContainer = styled.div`
  position: relative;
  overflow: hidden;
  width: fit-content;
`;

export const ProfileImage = styled.img`
  width: 100%;
  border-radius: 10px;
`;

export const EditImageOverlay = styled.div`
  background-color: rgba(0, 0, 0, 0.4);
  color: #ccc;
  font-size: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  width: 100%;
  position: absolute;
  bottom: 6px;
  left: 0px;
  right: 0px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  cursor: pointer;

  &:hover {
    color: white;
  }
`;

export const CoverImageHolder = styled.label`
  cursor: pointer;
  height: 60px;
  width: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
