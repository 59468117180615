export const SEARCH_FILTERS = [
  {
    text: "Performing Arts",
  },
  {
    text: "Music",
  },
  {
    text: "Fine Art",
  },
  {
    text: "Digital Arts",
  },
  {
    text: "Crafts",
  },
  {
    text: "Fabrication",
  },
  {
    text: "Workspaces",
  },
  {
    text: "$0 - $50",
  },
  {
    text: "$51 - $100",
  },
];

export const SEARCH_CATEGORIES = [
  "Performing Arts",
  "Music",
  "Fine Art",
  "Digital Arts",
  "Crafts",
  "Fabrication",
];

export const SEARCH_TYPE = [
  { name: "Item", value: "item" },
  { name: "Workspace", value: "workspace" },
];

export const SEARCH_DICTIONARY = {
  "Performing Arts": { text: "Performing Arts", isChecked: false },
  Music: { text: "Music", isChecked: false },
  "Fine Art": { text: "Fine Art", isChecked: false },
  "Digital Arts": { text: "Digital Arts", isChecked: false },
  Crafts: { text: "Crafts", isChecked: false },
  Fabrication: { text: "Fabrication", isChecked: false },
  Workspaces: { text: "Workspaces", isChecked: false },
  "$0 - $50": { text: "$0 - $50", isChecked: false },
  "$51 - $100": { text: "$51 - $100", isChecked: false },
};
