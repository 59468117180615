import React from "react";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    maxWidth: 500,
    textAlign: "left",
    paddingBottom: 10,
  },
  checkBoxContainer: {
    display: "flex",
    alignItems: "center",
  },
}));

const OptionPicker = (props) => {
  const { options, name, register } = props;
  const classes = useStyles();
  return (
    <Box className={classes.container}>
      {options.map((option) => {
        return (
          <Box key={option + name} className={classes.checkBoxContainer}>
            <input
              type="checkbox"
              value={option}
              name="name"
              {...register(`${name}.${option}`)}
            />
            <span>{option}</span>
          </Box>
        );
      })}
    </Box>
  );
};

export default OptionPicker;
