import { Box, useMediaQuery } from "@mui/material";

import makeStyles from '@mui/styles/makeStyles';
import { colors } from "theme";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: 30,
    paddingBottom: 100,
    backgroundColor: colors.lightYellow,
  },
  title: {
    fontSize: 35,
    marginBottom: 20,
  },
  subTitle: {
    maxWidth: 500,
    margin: "auto",
    textAlign: "center",
    fontSize: 18,
    marginBottom: 30,
    lineHeight: 1.3,
    padding: 5,
  },
  video: {
    maxWidth: "100%",
  },
}));

const MidSection = () => {
  const mobile = useMediaQuery("(min-width:800px)");
  const classes = useStyles(mobile);
  return (
    <Box className={classes.container}>
      <Box className={classes.title}>
        We know it's hard to find the creative tools you need...
      </Box>
      <Box className={classes.subTitle}>
        It can be prohibitively expensive to buy them outright, especially when
        your usage will be limited. We also know that those same tools are often
        collecting dust in garages and storage units all around your city.
      </Box>
      <Box className={classes.subTitle}>
        We provide a no-hassle way for you to find what you want at a price that
        makes sense. We'll connect you to the owner of a space or a piece of
        equipment so you can rent what you need to keep working.
      </Box>
      <Box>
        <video
          src="https://file-upload-dev.s3.amazonaws.com/assets/Rent+Art+Stuff+v3.mp4#t=0.2"
          controls
          controlsList="nodownload"
          width={"90%"}
          style={{ margin: "auto" }}
          type="video/mp4"
        />
      </Box>
      <Box className={classes.title}>
        When we work together, everybody wins!
      </Box>
    </Box>
  );
};

export default MidSection;
