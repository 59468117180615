import { ENDPOINTS } from "../endpoints";
import { createApi } from "../setup";

const useRentalsApi = () => {
  const createNewRental = async (data) => {
    const API = await createApi();
    // data example
    // renter_user_id: "auth0|60708c12910b6d0071982ee7",
    // listing_id: 19,
    // rental_price: "20",
    // rental_time: "Week",
    // start_date: now.toUTCString(),
    // start_time: now.toUTCString(),
    // end_date: now.toUTCString(),
    // end_time: now.toUTCString(),

    const resp = await API.post(`${ENDPOINTS.RENTALS}`, data);
    return resp;
  };

  const getRenterInfo = async (rentalId) => {
    const API = createApi();
    try {
      const resp = await API.get(
        `${ENDPOINTS.RENTALS_RENTER_INFO}/${rentalId}`
      );
      return resp;
    } catch (e) {
      return e;
    }
  };

  const editRental = async (data) => {
    const API = await createApi();

    try {
      const resp = await API.put(ENDPOINTS.RENTALS, data);
      return resp;
    } catch (e) {
      return e;
    }
  };

  const deleteRental = async (rentalId) => {
    const API = await createApi();
    const resp = await API.delete(`${ENDPOINTS.RENTALS}/${rentalId}`);
    return resp;
  };

  const updateRentalState = async (rentalId, status) => {
    const API = await createApi();

    try {
      const resp = await API.post(`${ENDPOINTS.RENTALS}/update`, {
        rentalId,
        status
      });
      return resp;
    } catch (e) {
      return e;
    }
  }

  return {
    createNewRental,
    editRental,
    deleteRental,
    getRenterInfo,
    updateRentalState
  };
};

export default useRentalsApi;
