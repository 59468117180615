import React from "react";
import { Box, Button, Modal } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import useSWR from "swr";
import { ENDPOINTS } from "../../../../api/endpoints";
import { useForm } from "react-hook-form";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    alignItems: "center"
  },
  formWrapper: {
    padding: "1rem",
    background: "#eee",
  }
}));

const CreateQuoteModal = (props) => {
  const classes = useStyles();
  const { modalOpen, handleClose, productInfo, quoteHandler } = props;
  const { data: listing } = useSWR(
    `${ENDPOINTS.LISTINGS}/single/${productInfo.listing_id}`
  );

  const {
    register,
    handleSubmit,
  } = useForm({});

  return (
    <Modal open={modalOpen} fullScreen={false}>
      <Box className={classes.container}>
        <div className={classes.formWrapper}>
          {
            listing &&
            <form onSubmit={handleSubmit(quoteHandler)}>
              <div>
                <label htmlFor="rate">Rate:</label>
                <input type="number" name="rate" {...register("rate", { required: true })} defaultValue={listing.unit_price} />
                <span>$/{listing.unit_time}</span>
              </div>
              <div>
                <label htmlFor="duration">Duration:</label>
                <input type="number" name="duration" {...register("duration", { required: true })} defaultValue={1} />
                <span>{listing.unit_time}(s)</span>
              </div>
              <div>
                <Button color="error" type="button" onClick={() => handleClose()}>Cancel</Button>
                <Button type="submit">OK</Button>
              </div>
            </form>
          }
        </div>
      </Box>
    </Modal>
  );
};

export default CreateQuoteModal;
