import React, { useEffect } from "react";
import styled from "@emotion/styled";
import { colors } from "theme";
import useRentalsApi from "../../../api/rentals";
import { navigate } from "@reach/router";

const BoxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 320px;
  height: 240px;
  padding: 20px;
  border-radius: 12px;
  background-color: ${colors.green};
`;

const PageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
`;

const Title1 = styled.h2`
  margin-bottom: 10px;
  color: #fff;
`;

const Title2 = styled.h3`
  color: #fff;
`;

const PaymentSuccessPage = ({ isMobile }) => {
  const [rental, setRental] = React.useState(null);
  useEffect(() => {
    const tmp = localStorage.getItem("curr_rental");
    setRental(JSON.parse(tmp));
  }, []);
  const { updateRentalState } = useRentalsApi();

  useEffect(() => {
    if (rental) {
      setTimeout(async () => {
        await updateRentalState(rental.id, "Paid");
        navigate('/');
      }, 3000);
    }
  }, [rental]);

  return (
    <PageWrapper>
      <BoxWrapper>
        <Title1>Invoice is paid successfully.</Title1>
        <Title2>Wait for a while is redirecting.</Title2>
      </BoxWrapper>
    </PageWrapper>
  );
};

export default PaymentSuccessPage;
