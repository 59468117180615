export const LISTING_CATEGORY_OPTIONS = [
  "Performing Arts",
  "Music",
  "Fine Art",
  "Digital Arts",
  "Crafts",
  "Fabrication",
];

export const LISTING_UNIT_TIME_OPTIONS = [
  "Day",
  "Week",
  "Hour",
  "Month",
  "Year",
  "Other",
];

export const LISTING_TYPE_OPTIONS = ["item", "workspace"];

export const LISTING_EXCHANGE_OPTIONS = ["In Person", "Drop Off", "Shipped"];

export const LISTING_AVAILABILITY_OPTIONS = ["Available", "Not Available"];
