import React, { useState, useEffect } from "react";
import { Box, InputAdornment, Chip } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import PageWrapper from "../../components/PageWrapper";
import useSWR from "swr";
import { ENDPOINTS } from "../../api/endpoints";
import CommonInput from "../../components/CommonInput";
import LocationSearch from "./LocationSearch";
import { MonetizationOn } from "@mui/icons-material";
import CommonSelect from "../../components/CommonSelect";
import {
  LISTING_AVAILABILITY_OPTIONS,
  LISTING_CATEGORY_OPTIONS,
  LISTING_EXCHANGE_OPTIONS,
  LISTING_TYPE_OPTIONS,
  LISTING_UNIT_TIME_OPTIONS,
} from "./static";
import CommonButton from "../../components/CommonButton";
import { useEditListingState } from "../../state/editListingStates";
import EditImagesModal from "./modals/EditImagesModal";
import AddImageModal from "./modals/AddImageModal";
import PreviewSaveModal from "./modals/PreviewSaveModal";
import { colors } from "theme";

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: "100vh",
    backgroundColor: colors.lightYellow,
  },
  formControl: {
    width: "100%",
    textAlign: "left",
  },
  tagGroup: {},
  buttonGroup: {
    display: "flex",
    justifyContent: "center",
    paddingBottom: 50,
  },
  button: {
    margin: 5,
  },
}));
const EditListingPage = (props) => {
  const [tagString, setTagString] = useState("");
  const [isEditImageModalOpen, setIsEditImageModalOpen] = useState(false);
  const [isAddImageModalOpen, setIsAddImageModalOpen] = useState(false);
  const [isPreviewSaveModalOpen, setIsPreviewSaveModalOpen] = useState(false);
  const {
    data: listing,
    error,
    revalidate,
  } = useSWR(`${ENDPOINTS.LISTINGS}/single/${props.id}`);

  const {
    //functions
    handleNameChange,
    handleDescriptionChange,
    handlePriceChange,
    handleTimeUnitChange,
    handlePriceDescriptionChange,
    handleMaxTimeChange,
    handleMinTimeChange,
    handlePrimaryCategoryChange,
    handleSecondaryCategoriesChange,
    handleExchangeMethodChange,
    handleAvailabilityChange,
    handleTypeChange,
    handleNotesChange,
    handleTagsChange,
    handleRemoveTag,
    handleCityChange,
    handleFillTags,
    handleCollateralChange,
    //vars
    // name,
    // description,
    // price,
    timeUnit,
    // minTime,
    // maxTime,
    primaryCategory,
    secondaryCategories,
    // exchangeMethod,
    // availability,
    // type,
    tags,
    city,
  } = useEditListingState();
  useEffect(() => {
    if (listing) {
      handleNameChange(listing.name);
      handleDescriptionChange(listing.description);
      handlePriceChange(listing.unit_price);
      handleTimeUnitChange(listing.unit_time);
      handlePriceDescriptionChange(listing.price_description);
      handleMaxTimeChange(listing.max_time);
      handleMinTimeChange(listing.min_time);
      handlePrimaryCategoryChange(listing.primary_category);
      handleSecondaryCategoriesChange(listing.sub_categories);
      handleExchangeMethodChange(listing.exchange_method);
      handleAvailabilityChange(
        listing.is_currently_available ? "Available" : "Not Available"
      );
      handleTypeChange(listing.type);
      handleFillTags(listing.tags);
      handleCityChange(listing.location_city);
    }
    // eslint-disable-next-line
  }, [listing]);

  const classes = useStyles();
  return (
    <PageWrapper isNavBarShowing>
      {listing && (
        <Box className={classes.container}>
          <Box component="h1" p={3}>
            THIS PAGE IS FOR EDITING A LISTING
          </Box>
          <CommonInput
            defaultValue={listing.name}
            title={"Name"}
            onChange={(value) => handleNameChange(value)}
            InputProps={{
              maxLength: 240,
            }}
          />
          <CommonInput
            defaultValue={listing.description}
            title={"Description"}
            onChange={(value) => handleDescriptionChange(value)}
            InputProps={{
              maxLength: 240,
            }}
          />
          <Box display="flex">
            <Box width="50%">
              <CommonInput
                defaultValue={listing.unit_price}
                type="number"
                title={"Price (USD)"}
                fullWidth
                onChange={(value) => handlePriceChange(value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <MonetizationOn />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            <Box width="50%">
              <CommonSelect
                options={LISTING_UNIT_TIME_OPTIONS}
                defaultValue={listing.unit_time}
                handleChange={(e) => handleTimeUnitChange(e.target.value)}
                name={"Time Unit"}
              />
            </Box>
          </Box>
          {timeUnit === "Other" && (
            <CommonInput
              defaultValue={listing.price_description}
              title={"Price Description"}
              onChange={(value) => handlePriceDescriptionChange(value)}
            />
          )}
          {timeUnit !== "Other" && (
            <Box>
              <CommonInput
                defaultValue={listing.min_time}
                type="number"
                title={"Min Time"}
                onChange={(value) => handleMinTimeChange(value)}
                InputProps={{
                  maxLength: 9,
                }}
              />
              <CommonInput
                defaultValue={listing.max_time}
                type="number"
                title={"Max Time"}
                onChange={(value) => handleMaxTimeChange(value)}
                InputProps={{
                  maxLength: 9,
                }}
              />
            </Box>
          )}
          <CommonSelect
            options={LISTING_CATEGORY_OPTIONS}
            defaultValue={listing.primary_category}
            currentValue={primaryCategory}
            handleChange={(e) => handlePrimaryCategoryChange(e.target.value)}
            name={"Primary Category"}
          />
          <CommonSelect
            options={LISTING_CATEGORY_OPTIONS}
            handleChange={(value) => handleSecondaryCategoriesChange(value)}
            multiple
            currentValue={secondaryCategories}
            name={"Secondary Categories (optional)"}
          />
          <CommonSelect
            options={LISTING_EXCHANGE_OPTIONS}
            defaultValue={listing.exchange_method}
            handleChange={(e) => handleExchangeMethodChange(e.target.value)}
            name={"Exchange Method"}
          />
          <CommonSelect
            options={LISTING_AVAILABILITY_OPTIONS}
            defaultValue={
              listing.is_currently_available ? "Available" : "Not Available"
            }
            handleChange={(e) => handleAvailabilityChange(e.target.value)}
            name={"Availability"}
          />
          <CommonSelect
            options={LISTING_TYPE_OPTIONS}
            name={"Type"}
            defaultValue={listing.type}
            handleChange={(e) => handleTypeChange(e.target.value)}
          />
          <CommonInput
            defaultValue={listing.notes}
            title={"Notes"}
            onChange={(value) => handleNotesChange(value)}
            InputProps={{
              maxLength: 240,
            }}
          />
          <CommonInput
            defaultValue={listing.collateral}
            title={"Collateral"}
            onChange={(value) => handleCollateralChange(value)}
            InputProps={{
              maxLength: 240,
            }}
          />
          <CommonInput
            title={"Tags"}
            onChange={(value) => setTagString(value)}
            value={tagString}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <CommonButton
                    text="Create Tag"
                    handleClick={() => {
                      handleTagsChange(tagString);
                      setTagString("");
                    }}
                    buttonVariant="normal"
                    isUppercase
                  />
                </InputAdornment>
              ),
              maxLength: 240,
            }}
          />
          <Box className={classes.tagGroup}>
            {tags &&
              tags.map((tag) => (
                <Chip
                  label={tag}
                  onDelete={() => {
                    handleRemoveTag(tag);
                  }}
                  variant="outlined"
                  key={tag}
                />
              ))}
          </Box>
          <LocationSearch
            defaultValue={listing.location_city}
            handleChange={handleCityChange}
            currentValue={city}
          />
          <Box className={classes.buttonGroup}>
            <Box className={classes.button}>
              <CommonButton
                text="Edit Images"
                isUppercase
                handleClick={() => setIsEditImageModalOpen(true)}
              />
            </Box>
            <Box className={classes.button}>
              <CommonButton
                text="Add Images"
                isUppercase
                handleClick={() => setIsAddImageModalOpen(true)}
              />
            </Box>
          </Box>
          <Box className={classes.buttonGroup}>
            <CommonButton
              text="Preview Changes"
              isUppercase
              buttonVariant="coolBlue"
              handleClick={() => setIsPreviewSaveModalOpen(true)}
            />
          </Box>
          <EditImagesModal
            open={isEditImageModalOpen}
            assets={listing.assets}
            handleClose={() => setIsEditImageModalOpen(false)}
            refresh={revalidate}
          />
          <AddImageModal
            open={isAddImageModalOpen}
            handleClose={() => setIsAddImageModalOpen(false)}
            listing={listing}
            refresh={revalidate}
          />
          <PreviewSaveModal
            open={isPreviewSaveModalOpen}
            handleClose={() => setIsPreviewSaveModalOpen(false)}
            listing={listing}
            refresh={revalidate}
          />
        </Box>
      )}
      {error && <Box>ERROR OCURRED HERE FOR SOME REASON</Box>}
    </PageWrapper>
  );
};

export default EditListingPage;
