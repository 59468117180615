import React from "react";
import DataTable from 'react-data-table-component';
import {
  BalanceContainer,
  HeaderWrapper,
  IncomeSpan,
  OutcomeSpan
} from "./styled";
import { Button } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import useSWR from "swr";
import { ENDPOINTS } from "../../../api/endpoints";
import * as dayjs from "dayjs";
// import SearchIcon from "@mui/icons-material/Search";

const columns = [
  {
    name: 'No',
    selector: row => row.id,
  },
  {
    name: 'DateTime',
    selector: row => row.end_date_time,
    sortable: true,
  },
  {
    name: 'Description',
    selector: row => `${row.renter_name} is borrowed.`,
  },
  {
    name: 'Amount',
    selector: row => {
      const date1 = dayjs(row.start_date_time);
      const date2 = dayjs(row.end_date_time);
      const duration = date2.diff(date1, row.rental_time_unit.toLowerCase());
      const estimatedPrice = duration * row.rental_price;
      return <IncomeSpan>
        + ${estimatedPrice}
      </IncomeSpan>
      // if (row.owner_user_id === ) {
      // } else {
      //   return <OutcomeSpan>
      //     - ${Math.abs(estimatedPrice)}
      //   </OutcomeSpan>
      // }
    },
    sortable: true,
  },
  {
    name: 'Note',
    selector: row => row.note,
  },
  {
    name: 'Action',
    selector: row => (
      <>
        <Button color="secondary" size="small" style={{
          minWidth: "32px",
          width: "32px",
        }}>
          <DownloadIcon />
        </Button>
        {/* <Button color="secondary" size="small">
          <SearchIcon />
        </Button> */}
      </>
    )
  }
];

const data = [
  {
    id: 1,
    desc: 'abc',
    amount: 250,
    note: '',
    updatedAt: '2022-05-05',
    type: 'income'
  },
  {
    id: 2,
    desc: 'def',
    amount: -130,
    note: '',
    updatedAt: '2022-05-05',
    type: 'outcome'
  },
  {
    id: 3,
    desc: 'def',
    amount: -25,
    note: '',
    updatedAt: '2022-05-05',
    type: 'outcome'
  },
  {
    id: 4,
    desc: 'def',
    amount: 45,
    note: '',
    updatedAt: '2022-05-05',
    type: 'outcome'
  },
  {
    id: 5,
    desc: 'def',
    amount: 65,
    note: '',
    updatedAt: '2022-05-05',
    type: 'outcome'
  },
];

const customStyles = {
  rows: {
    style: {
      // minHeight: '72px', // override the row height
    },
  },
  headCells: {
    style: {
      paddingLeft: '8px', // override the cell padding for head cells
      paddingRight: '8px',
    },
  },
  cells: {
    style: {
      paddingLeft: '8px', // override the cell padding for data cells
      paddingRight: '8px',
    },
  },
};

// const conditionalRowStyles = [
//   {
//     when: row => row.type == 'outcome',
//     style: {
//       backgroundColor: '#ccc',
//       '&:hover': {
//         cursor: 'pointer',
//       },
//     },
//   },
// ];

const Balance = ({ isMobile }) => {
  const { data: me, mutate } = useSWR(ENDPOINTS.USERS_ME);
  const { data: rentals, mutate: refreshRentals } = useSWR(ENDPOINTS.RENTALS);

  return (
    <BalanceContainer isMobile={isMobile}>
      <HeaderWrapper>
        <span>
          Balance:
          <strong>
            ${me && me.balance}
          </strong>
        </span>
        <Button>Purchase</Button>
      </HeaderWrapper>
      <DataTable
        title="Transaction History"
        columns={columns}
        customStyles={customStyles}
        data={rentals}
        pagination
        highlightOnHover
        // conditionalRowStyles={conditionalRowStyles}
      />
    </BalanceContainer>
  );
};

export default Balance;
