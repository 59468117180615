import { atom, useRecoilState } from "recoil";

const nameState = atom({
  key: "editListingNameState",
  default: "",
});

const descriptionState = atom({
  key: "editListingDescriptionState",
  default: "",
});

const priceState = atom({
  key: "editListingPriceState",
  default: "",
});

const timeUnitState = atom({
  key: "editListingTimeUnitState",
  default: "",
});

const minTimeState = atom({
  key: "editListingMinTimeState",
  default: "",
});

const maxTimeState = atom({
  key: "editListingMaxTimeState",
  default: "",
});

const primaryCategoryState = atom({
  key: "editListingPrimaryCategoryState",
  default: "",
});

const secondaryCategoryState = atom({
  key: "editListingSecondaryCategoryState",
  default: [],
});

const exchangeMethodState = atom({
  key: "editListingExchangeMethodState",
  default: "",
});

const availabilityState = atom({
  key: "editListingAvailabilityState",
  default: "",
});

const typeState = atom({
  key: "editListingTypeState",
  default: "",
});

const tagsState = atom({
  key: "editListingTagsState",
  default: [],
});

const cityState = atom({
  key: "editListingCityState",
  default: "",
});

const assetState = atom({
  key: "editListingAssetState",
  default: [],
});

const priceDescriptionState = atom({
  key: "editPriceDescriptionState",
  default: "",
});

const notesState = atom({
  key: "editNotesState",
  default: "",
});

const collateralState = atom({
  key: "editCollateralState",
  default: "",
});

const useEditListingState = () => {
  const [name, setName] = useRecoilState(nameState);
  const [description, setDescription] = useRecoilState(descriptionState);
  const [price, setPrice] = useRecoilState(priceState);
  const [priceDescription, setPriceDescription] = useRecoilState(
    priceDescriptionState
  );
  const [timeUnit, setTimeUnit] = useRecoilState(timeUnitState);
  const [minTime, setMinTime] = useRecoilState(minTimeState);
  const [maxTime, setMaxTime] = useRecoilState(maxTimeState);
  const [primaryCategory, setPrimaryCategory] =
    useRecoilState(primaryCategoryState);
  const [secondaryCategories, setSecondaryCategories] = useRecoilState(
    secondaryCategoryState
  );
  const [exchangeMethod, setExchangeMethod] =
    useRecoilState(exchangeMethodState);
  const [availability, setAvailability] = useRecoilState(availabilityState);
  const [type, setType] = useRecoilState(typeState);
  const [notes, setNotes] = useRecoilState(notesState);
  const [collateral, setCollateral] = useRecoilState(collateralState);
  const [tags, setTags] = useRecoilState(tagsState);
  const [city, setCity] = useRecoilState(cityState);
  const [assets, setAssets] = useRecoilState(assetState);

  // ====================== handle change functions
  const handleNameChange = (value) => {
    setName(value);
  };
  const handleDescriptionChange = (value) => {
    setDescription(value);
  };
  const handlePriceChange = (value) => {
    setPrice(value);
  };
  const handleTimeUnitChange = (value) => {
    setTimeUnit(value);
  };

  const handlePriceDescriptionChange = (value) => {
    setPriceDescription(value);
  };

  const handleMinTimeChange = (value) => {
    setMinTime(value);
  };
  const handleMaxTimeChange = (value) => {
    setMaxTime(value);
  };
  const handlePrimaryCategoryChange = (value) => {
    setPrimaryCategory(value);
  };
  const handleSecondaryCategoriesChange = (value) => {
    setSecondaryCategories(value);
  };
  const handleExchangeMethodChange = (value) => {
    setExchangeMethod(value);
  };
  const handleAvailabilityChange = (value) => {
    setAvailability(value);
  };
  const handleTypeChange = (value) => {
    setType(value);
  };
  const handleNotesChange = (value) => {
    setNotes(value);
  };
  const handleCollateralChange = (value) => {
    setCollateral(value);
  };
  const handleTagsChange = (value) => {
    const old = tags;
    setTags([...old, value]);
  };
  const handleFillTags = (tagArray) => {
    setTags(tagArray);
  };
  const handleRemoveTag = (value) => {
    const old = tags;
    const toBeSet = old.filter((tag) => tag !== value);
    setTags(toBeSet);
  };

  const handleCityChange = (value) => {
    setCity(value);
  };

  const handleAssetChange = (value) => {
    setAssets(value);
  };

  const handleRemoveAsset = (value) => {
    const old = assets;
    const toBeSet = old.filter((tag) => tag !== value);
    setAssets(toBeSet);
  };

  return {
    // vars
    name,
    description,
    price,
    timeUnit,
    priceDescription,
    minTime,
    maxTime,
    primaryCategory,
    secondaryCategories,
    exchangeMethod,
    availability,
    type,
    notes,
    tags,
    city,
    assets,
    collateral,

    // handle Change functions
    handleNameChange,
    handleDescriptionChange,
    handlePriceChange,
    handleTimeUnitChange,
    handlePriceDescriptionChange,
    handleMinTimeChange,
    handleMaxTimeChange,
    handlePrimaryCategoryChange,
    handleSecondaryCategoriesChange,
    handleExchangeMethodChange,
    handleAvailabilityChange,
    handleTypeChange,
    handleNotesChange,
    handleCollateralChange,
    handleTagsChange,
    handleRemoveTag,
    handleCityChange,
    handleAssetChange,
    handleRemoveAsset,
    handleFillTags,
  };
};

export { useEditListingState };
