import styled from "@emotion/styled";
import { colors } from "theme";

export const CropButton = styled.button`
  z-index: 2;
  cursor: pointer;
  width: 100%;
  background-color: ${colors.blue};
  color: #fff;
  padding: 10px 0;
  margin-top: 20px;
  text-align: center;
  font-weight: bold;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const CropContainer = styled.div`
  height: ${(props) => (props.isMobile ? "400px" : "600px")};
  width: ${(props) => (props.isMobile ? "100%" : "600px")};
`;

export const Controls = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.isMobile ? "column" : "row")};
  padding-top: 10px;
  width: 100%;
  position: absolute;
  bottom: -15px;
  background: #999;
  padding-inline: 15px;
  padding-bottom: 10px;
`;

export const ControlLabel = styled.label`
  width: 100%;
  margin-inline: 10px;
  text-align: left;
  font-size: 14px;
`;

export const CropImageContainer = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
`;

export const CancelButton = styled(CropButton)`
  background: orange;
`;
