import { atom, useRecoilState } from "recoil";

const listingNotesState = atom({
  key: "listingNotesState",
  default: "",
});

const useStep5State = () => {
  const [listingNotes, setListingNotes] = useRecoilState(listingNotesState);

  const handleListingNotesChange = (value) => {
    setListingNotes(value);
  };

  const clearStep5 = () => {
    setListingNotes("");
  };

  return {
    listingNotes,
    setListingNotes,
    handleListingNotesChange,
    clearStep5,
  };
};

export { useStep5State };
