import React, { Component, useState, useEffect } from 'react';
import '../style.css';

function InvoiceHeader({ invoiceNumber, rental }) {
    const [paid, setpaid] = useState(false)

    useEffect(() => {
        setpaid(false);
        console.log(rental)
    }, []);

    return (

        <header>

            {
                rental &&
                <>
                    <h1 > INVOICE #{invoiceNumber}&nbps;
                        {(rental.status === "Paid") && <span className='badge-success'>PAID</span>}
                        {(rental.status === "NotPaid") && <span className='badge-error'>NOT PAID</span>}
                        {(rental.status === "Declined") && <span className='badge-error'>DECLINED</span>}
                    </h1>
                    <address>
                        <h3 style={{ textAlign: "left", }}>From: </h3>
                        <p> {rental.renter_name} </p>
                        <p> {rental.renter_email} </p>
                        {/* <p> +918660876889 </p> */}
                    </address>
                </>
            }

            <span>
                <img alt="MAHESH" src={`${process.env.REACT_APP_ORIGIN}/art-assets/logo.png`} className="rounded float-right align-top" />
            </span>

        </header>
    )

}
export default InvoiceHeader;
