import React, { useState } from 'react'
import { Box, useMediaQuery } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import DetailLine from './components/DetailLine'
import { colors } from '../../theme'

const useStyles = makeStyles(theme => ({
  rowContainer: {
    display: 'flex'
  },
  flexContainer: {
    flex: 1
  },
  container: {
    textAlign: 'left',
    paddingTop: 10,
    width: props => (props.isMobile ? '95%' : 500)
  },
  title: {
    fontSize: 25,
    fontWeight: 'bold',
    marginBottom: 10
  },
  show: {
    cursor: 'pointer',
    width: 'fit-content',
    color: colors.blueGrotto,
    '&:hover': {
      textDecoration: 'underline'
    }
  }
}))

const DetailsSection = props => {
  // Need a route on the backend to return true or false or something fast to determine if the listing has been saved or not
  const { listing } = props
  const isMobile = useMediaQuery('(max-width:800px)')
  const classes = useStyles({ isMobile })
  const [isShowing, setIsShowing] = useState(false)

  return (
    <Box className={classes.container}>
      <Box onClick={() => setIsShowing(!isShowing)} className={classes.show}>
        {isShowing ? 'Hide Details' : 'Show Details'}
      </Box>
      {isShowing && (
        <>
          <Box className={classes.rowContainer}>
            <Box className={classes.flexContainer}>
              <DetailLine
                title={'Category'}
                content={listing.primary_category}
              />
              <DetailLine title={'Type'} content={listing.type} />
              {listing.unit_time !== 'Other' && (
                <Box>
                  <DetailLine
                    title={'Minimum Rental Time'}
                    content={`${listing.min_time} ${listing.unit_time}(s)`}
                  />
                  <DetailLine
                    title={'Maximum Rental Time'}
                    content={`${listing.max_time} ${listing.unit_time}(s)`}
                  />
                </Box>
              )}

              <DetailLine
                title={'Availability'}
                content={
                  listing.is_currently_available
                    ? 'Currently Available'
                    : 'Not Available'
                }
              />
            </Box>
            <Box className={classes.flexContainer}>
              <DetailLine
                title={'Exchange Method'}
                content={listing.exchange_method}
              />
              {listing.contact_email && (
                <DetailLine title={'Contact'} content={listing.contact_email} />
              )}
              {listing.notes && (
                <DetailLine title={'Notes'} content={listing.notes} />
              )}
              {listing.collateral && (
                <DetailLine title={'Collateral'} content={listing.collateral} />
              )}
            </Box>
          </Box>
        </>
      )}
    </Box>
  )
}

export default DetailsSection
