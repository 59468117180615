// import React, { useState } from "react";
import { Box } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import PageWrapper from "../../components/PageWrapper";
// import StepButtons from "./StepButtons";
// import Step3 from "./Steps/Step3";
// import Step4 from "./Steps/Step4";
// import Step5 from "./Steps/Step5";
// import Step6 from "./Steps/Step6";
// import GeneralInformation from "./Steps/GeneralInformation.js";
import Refactor from "./Refactor";
import { colors } from "theme";

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: "100vh",
    paddingBottom: 100,
    overflow: "auto",
    width: "100%",
    backgroundColor: colors.lightYellow,
  },
  stepContainer: {
    backgroundColor: colors.lightYellow,
  },
  step: {
    position: "relative",
  },
}));

const CreateListingPage = () => {
  const classes = useStyles();
  // const [isReviewReady, setIsReviewReady] = useState(false);
  // const [activeStep, setActiveStep] = useState(0);
  // const [skipped, setSkipped] = useState(new Set());

  // // step names
  // const getSteps = () => {
  //   return ["Step1", "Step2", "Step3", "Step4", "Step5"];
  // };
  // const steps = getSteps();

  // Step components
  // const getStepContent = (step) => {
  //   switch (step) {
  //     case 0:
  //       return <GeneralInformation />;
  //     case 1:
  //       return <Step3 />;
  //     case 2:
  //       return <Step4 />;
  //     case 3:
  //       return <Step5 />;
  //     case 4:
  //       // return <Step6 setIsReviewReady={setIsReviewReady} />;
  //     default:
  //       return "Unknown step";
  //   }
  // };

  // const isStepOptional = (step) => {
  //   // return step === 1;
  // };

  // const isStepSkipped = (step) => {
  //   return skipped.has(step);
  // };

  return (
    <PageWrapper isNavBarShowing>
      <Box className={classes.container}>
        <Box component="h1">Create a listing</Box>
        {/* {!isReviewReady && (
          <>
            <GeneralInformation />
            <Step4 />
            <Step5 />
            <Button
              onClick={() => setIsReviewReady(true)}
              variant="contained"
              color="primary"
            >
              Review & Submit
            </Button>
          </>
        )} */}
        {/* {isReviewReady && <Step6 setIsReviewReady={setIsReviewReady} />}
        <Stepper
          activeStep={activeStep}
          alternativeLabel
          className={classes.stepContainer}
        >
          {steps.map((label, index) => {
            const stepProps = {};
            const labelProps = {};
            if (isStepOptional(index)) {
              labelProps.optional = (
                <Typography variant="caption">Optional</Typography>
              );
            }
            if (isStepSkipped(index)) {
              stepProps.completed = false;
            }
            return (
              <Step key={label} {...stepProps} className={classes.step}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>

        <StepButtons
          getStepContent={getStepContent}
          isStepOptional={isStepOptional}
          activeStep={activeStep}
          steps={steps}
          isStepSkipped={isStepSkipped}
          setActiveStep={setActiveStep}
          skipped={skipped}
          setSkipped={setSkipped}
        /> */}
        <Refactor />
      </Box>
    </PageWrapper>
  );
};

export default CreateListingPage;
