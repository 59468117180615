import { ENDPOINTS } from "../endpoints";
import { createApi } from "../setup";

const paymentsApi = () => {
  const createPaymentIntent = async (data) => {
    const API = await createApi();

    const resp = await API.post(`${ENDPOINTS.CREATE_PAYMENT_INTENT}`, data);
    return resp;
  };


  return {
    createPaymentIntent,
  };
};

export default paymentsApi;
