import React from "react";
import { Box, TextField, InputAdornment } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import SearchIcon from "@mui/icons-material/Search";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 10,
  },
  input: {
    outline: "none",
  },
  icon: {
    fontSize: 40,
    cursor: "pointer",
    color: "white",
    backgroundColor: theme.palette.primary.main,
    borderRadius: "50%",
  },
}));
const SearchBar = ({ value, onChange }) => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <TextField
        label="Search"
        variant="outlined"
        onChange={(e) => onChange(e.target.value)}
        fullWidth
        value={value}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        className={classes.input}
      />
    </Box>
  );
};

export default SearchBar;
