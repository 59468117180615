import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import RequestCard from "./RequestCard";
import useSWR from "swr";
import { ENDPOINTS } from "../../../../api/endpoints";
import { CardGroup, NoDataMessage, Tab, TabGroup } from "../styled";
import { REQUEST_TABS } from "../../_static";

const RequestsContainer = styled.div``;

const Requests = ({ user, isMobile }) => {
  const [currentTab, setCurrentTab] = useState("Sent");
  const [currentData, setCurrentData] = useState(null);
  const { data: rentalRequests, mutate: refreshRequests } = useSWR(
    ENDPOINTS.RENTAL_REQUESTS
  );
  const { data: contacts } = useSWR(ENDPOINTS.CONTACTS);

  useEffect(() => {
    const sentRequests = rentalRequests?.filter(
      (request) => request.created_by_user_id === user.internal_user_id
    );

    const receivedRequests = rentalRequests?.filter(
      (request) => request.created_by_user_id !== user.internal_user_id
    );
    setCurrentData(currentTab === "Sent" ? sentRequests : receivedRequests);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTab, rentalRequests, contacts]);

  const getContact = (request) => {
    if (currentTab === "Sent") {
      return contacts?.find(
        (contact) => request.receiver_user_id === contact.contact_user_id
      );
    } else {
      return contacts?.find(
        (contact) => request.sender_user_id === contact.contact_user_id
      );
    }
  };

  return (
    <RequestsContainer>
      <TabGroup>
        {REQUEST_TABS.map((tab) => (
          <Tab
            key={tab.title}
            onClick={() => setCurrentTab(tab.title)}
            selected={tab.title === currentTab}
          >
            {tab.title}
          </Tab>
        ))}
      </TabGroup>
      <CardGroup isMobile={isMobile}>
        {currentData &&
          currentData.map((request) => (
            <RequestCard
              refresh={refreshRequests}
              key={request.id}
              request={request}
              sent={currentTab === "Sent"}
              contact={getContact(request)}
            />
          ))}
      </CardGroup>
      {!currentData ||
        (currentData?.length < 1 && (
          <NoDataMessage>
            You do not have any requests at this time
          </NoDataMessage>
        ))}
    </RequestsContainer>
  );
};

export default Requests;
