import { useState } from "react";
import { Box, TextField } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import PageWrapper from "components/PageWrapper";
import { QUESTIONS } from "./static";
import Question from "./Question";
import CommonButton from "components/CommonButton";
import useSendFeedbackApi from "../../api/send-feedback";
import { colors } from "theme";
import { useAuth0 } from "@auth0/auth0-react";
import { ENDPOINTS } from "api/endpoints";
import useSWR from "swr";
import styled from "@emotion/styled";
import dayjs from "dayjs";

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: "100vh",
    backgroundColor: colors.lightYellow,
  },
  header: {
    fontSize: 25,
    fontWeight: "bold",
    padding: 20,
  },
  feedbackContainer: {
    marginTop: 50,
  },
  input: {
    maxWidth: 500,
  },
  button: {
    width: "fit-content",
    margin: "auto",
    paddingTop: 30,
  },
  success: {
    marginTop: 50,
  },
}));

const FeedbackHeader = styled.div`
  font-size: 26px;
  margin-bottom: 40px;
`;

const FeedbackLine = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${colors.blue};
  padding-top: 10px;
  padding-inline: 10px;
`;

const Content = styled.div`
  font-size: 18px;
  text-align: left;
  max-width: 60%;
`;

const ContentTime = styled.div`
  font-size: 14px;
  text-align: right;
`;

const FaqPage = () => {
  const classes = useStyles();
  const { isAuthenticated } = useAuth0();
  const [message, setMessage] = useState(undefined);
  const [success, setSuccess] = useState(false);
  const { sendMessage } = useSendFeedbackApi();
  const { data, mutate: refresh } = useSWR(ENDPOINTS.USER_FEEDBACK);
  const handleSendMessage = async () => {
    await sendMessage(message);
    refresh();
    setMessage("");
    setSuccess(true);
  };

  return (
    <PageWrapper isNavBarShowing>
      <Box className={classes.container}>
        <Box className={classes.header}>Frequently Asked Questions</Box>
        {QUESTIONS.map((question, index) => (
          <Question question={question} key={question.title} index={index} />
        ))}
        {!success && isAuthenticated && (
          <Box className={classes.feedbackContainer}>
            <TextField
              type="text"
              className={classes.input}
              label="User Feedback"
              color="secondary"
              variant="filled"
              multiline
              fullWidth
              onChange={(e) => {
                setMessage(e.target.value);
              }}
            />
            <Box className={classes.button}>
              <CommonButton
                text="Send Message"
                buttonVariant="normal"
                handleClick={handleSendMessage}
                disabled={!message}
              />
            </Box>
          </Box>
        )}
        {success && isAuthenticated && (
          <Box className={classes.success}>Thank you for your feedback!</Box>
        )}
        <Box style={{ paddingTop: 20, paddingBottom: 20 }}>
          <FeedbackHeader>Here is what others had to say...</FeedbackHeader>
          {data &&
            data.map((feedback) => (
              <FeedbackLine>
                <Content>{feedback.content}</Content>
                <ContentTime>
                  {dayjs(feedback.created_at).format(
                    "dddd, MMM D, YY (h:mm a)"
                  )}
                </ContentTime>
              </FeedbackLine>
            ))}
        </Box>
      </Box>
    </PageWrapper>
  );
};

export default FaqPage;
