import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/material";
import { useForm } from "react-hook-form";
import CreateListingInput from "./components/CreateListingInput";
import OptionPicker from "./components/OptionPicker";
import CreateListingSelect from "./components/CreateListingSelect";
import CreateListingImages from "./components/CreateListingImages";
import { useStep4State } from "../../state/createListingStates/Step4";
import useListingsApi from "../../api/listings";
import useSWR from "swr";
import { navigate } from "@reach/router";
import { ENDPOINTS } from "../../api/endpoints";
import { useImageUploadApi } from "../../api";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 10,
  },
  header: {},
  labelText: {
    fontWeight: "bold",
    textAlign: "left",
  },
  selectContainer: {
    width: "fit-content",
    display: "flex",
    flexDirection: "column",
    paddingBottom: 10,
  },
  rentWarning: {
    maxWidth: 500,
  },
}));

const CreateListingPage = (props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      listing_name: "",
      description: "",
      listing_tags: "",
    },
  });
  const classes = useStyles();

  const { listingImages, clearStep4 } = useStep4State();
  const { createListing } = useListingsApi();
  const { uploadFile, saveImageToListing } = useImageUploadApi();
  const { data: user } = useSWR(ENDPOINTS.USERS_ME);
  const [imageError, setImageError] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [savingError, setSavingError] = useState(undefined);

  useEffect(() => {
    if (listingImages.length < 1) {
      setImageError(true);
    } else {
      setImageError(false);
    }
  }, [listingImages]);

  const waitAndNavigateToListing = (listing) => {
    navigate(`/listings/single/${listing.id}`);
  };

  const savingAssetToListing = async (listingId, reference) => {
    await saveImageToListing(listingId, reference);
  };

  const addAssets = async (file, listingId) => {
    const reference = await uploadFile(file, listingId, user);
    savingAssetToListing(listingId, reference);
  };

  const waitingOnImages = async (listingImages, listing) => {
    await Promise.all(
      listingImages.map(async (file) => {
        await addAssets(file, listing.id);
      })
    ).then(() => {
      return true;
    });
  };

  const get_sub_categories = (data) => {
    return Object.values(data).filter((item) => item === false);
  };

  const Submit = (data) => {
    if (!imageError) {
      // prep data
      const tags = data.listing_tags.split(",");
      const sub_categories = get_sub_categories(data.secondary_category);
      const data_for_upload = {
        name: data.listing_name,
        description: data.description,
        location_city: data.location,
        unit_price: Number(data.price),
        unit_time: data.rental_increment,
        type: data.listing_type === "Item" ? "item" : "workspace",
        min_time: data.min_time,
        max_time: data.max_time,
        primary_category: data.primary_category,
        sub_categories: sub_categories,
        tags: tags,
        is_currently_available:
          data.currently_available === "Yes" ? true : false,
        price_description: "", // add this to form
        exchange_method: data.exchange_method,
        notes: data.notes,
        collateral: data.collateral,
        contact_info_public: data.share_contact_info === "Yes" ? true : false,
      };
      createListings(data_for_upload);
    }
  };

  const createListings = async (data) => {
    setIsSaving(true);
    const listing = await createListing(data);
    await waitingOnImages(listingImages, listing)
      .then(() => {
        clearStep4();
        waitAndNavigateToListing(listing);
      })
      .catch((e) => {
        setSavingError(true);
        setIsSaving(false);
      });
  };

  const categories = [
    "Performing Arts",
    "Music",
    "Fine Art",
    "Digital Arts",
    "Crafts",
    "Fabrication",
  ];
  const rentalIncrements = [
    "Hour",
    "Day",
    "Week",
    "Month",
    "Year",
    "Use",
    "Other",
  ];
  const listingTypes = ["Item", "Workspace"];
  const exchangeMethods = ["In Person", "Drop Off", "Shipped"];
  const confirmOptions = ["Yes", "No"];

  return (
    <Box className={classes.container}>
      <form onSubmit={handleSubmit(Submit)}>
        <h3 className={classes.rentWarning}>
          Please do not rent out high value or sentimental items
        </h3>
        <CreateListingInput
          type="text"
          label="Listing Name"
          required
          error={errors.listing_name}
          errorText="Name is Required"
          register={register}
          name="listing_name"
          maxLength={240}
        />
        <CreateListingInput
          type="text"
          label="Listing Description"
          required
          error={errors.description}
          errorText="Description is Required"
          register={register}
          name="description"
          maxLength={240}
        />
        <CreateListingInput
          type="text"
          label="Listing Tags (Separated by ',' e.g. 'foam,sculpture,large scale,industrial')"
          register={register}
          name="listing_tags"
          maxLength={240}
        />
        <CreateListingSelect
          options={categories}
          name="primary_category"
          required
          error={errors.primary_category}
          label="Primary Category"
          register={register}
        />
        <Box className={classes.labelText}>Secondary Categories</Box>
        <OptionPicker
          options={categories}
          name="secondary_category"
          register={register}
        />
        <CreateListingInput
          type="text"
          label="Location (Be specific to the City/Town and State)"
          required
          placeholder="Las Vegas, NV"
          error={errors.location}
          errorText="Location is Required"
          register={register}
          name="location"
          maxLength={240}
        />
        <CreateListingInput
          type="number"
          label="Price (USD)"
          required
          error={errors.price}
          errorText="Price is Required"
          register={register}
          name="price"
          min="0"
          maxLength={9}
        />
        <CreateListingSelect
          options={rentalIncrements}
          name="rental_increment"
          required
          error={errors.rental_increment}
          label="Rent Time Increment"
          register={register}
        />
        <CreateListingInput
          type="number"
          label="Min Rent Time"
          register={register}
          name="min_time"
          min="0"
          maxLength={9}
        />
        <CreateListingInput
          type="number"
          label="Max Rent Time"
          register={register}
          name="max_time"
          min="0"
          maxLength={9}
        />
        <CreateListingSelect
          options={listingTypes}
          name="listing_type"
          required
          error={errors.listing_type}
          label="Listing Type"
          register={register}
        />
        <CreateListingSelect
          options={exchangeMethods}
          name="exchange_method"
          required
          error={errors.exchange_method}
          label="Exchange Method"
          register={register}
        />
        <CreateListingSelect
          options={confirmOptions}
          name="currently_available"
          required
          error={errors.currently_available}
          label="Currently Available"
          register={register}
        />
        <CreateListingInput
          type="text"
          placeholder="A pickup truck is needed to transport..."
          label="Comments/Notes"
          register={register}
          name="notes"
          maxLength={240}
        />
        <CreateListingInput
          type="text"
          label="Collateral"
          register={register}
          name="collateral"
          maxLength={240}
        />
        <CreateListingSelect
          options={confirmOptions}
          name="share_contact_info"
          required
          error={errors.share_contact_info}
          label="Make contact info public? (Email only)"
          register={register}
        />
        <CreateListingImages error={imageError} />
        {isSaving ? (
          <span>Please wait here while we Create your listing...</span>
        ) : savingError ? (
          <span style={{ color: "red" }}>
            Sorry! An error occurred while trying to create your listing.
          </span>
        ) : (
          <input type="submit" />
        )}
      </form>
    </Box>
  );
};

export default CreateListingPage;
