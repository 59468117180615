import React from "react";
import { Box, TextField } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  container: {
    marginBottom: 10,
  },
}));

const CommonInput = (props) => {
  const {
    type = "text",
    fullWidth = true,
    defaultValue,
    value,
    title,
    onChange,
    readOnly,
    InputProps,
  } = props;
  const classes = useStyles();
  return (
    <Box className={classes.container}>
      <TextField
        type={type}
        fullWidth={fullWidth}
        defaultValue={defaultValue}
        value={value}
        disabled={readOnly}
        title={title}
        variant="outlined"
        label={title}
        onChange={(e) => onChange(e.target.value)}
        InputProps={InputProps}
        inputProps={InputProps}
      />
    </Box>
  );
};

export default CommonInput;
