import "./App.css";
import { useEffect } from "react";
import { ThemeProvider, CssBaseline } from "@mui/material";
import theme from "./theme";
import Homepage from "./pages/Home";
import { RecoilRoot } from "recoil";
import { Router, navigate } from "@reach/router";
import AboutPage from "./pages/About";
import FaqPage from "./pages/FaqPage";
import SingleListingPage from "./pages/SingleListing";
import BrowseListingsPage from "./pages/BrowseListings";
import CreateListingPage from "./pages/CreateListing";
import EditListingPage from "./pages/EditListing";
import { useAuth0 } from "@auth0/auth0-react";
import { SWRConfig } from "swr";
import { swrFetcher } from "./api/setup";
import useUsersApi from "./api/users";
import UserDashboard from "./pages/UserDashboard";
import InvoicePage from "./pages/Invoice/invoice";
import CheckoutPage from "./pages/Checkout/checkout";
import PaymentSuccessPage from "./pages/UserDashboard/PaymentSuccessPage";

function App() {
  const { isAuthenticated, isLoading, getIdTokenClaims } = useAuth0();
  const { loginToBackend } = useUsersApi();
  useEffect(() => {
    if (isAuthenticated && !isLoading) {
      async function setTokenToLocalStorage() {
        const idToken = await getIdTokenClaims();
        localStorage.setItem(
          "auth",
          JSON.stringify({ id_token: idToken.__raw })
        );
        await loginToBackend();
      }
      setTokenToLocalStorage();
    }
  }, [isAuthenticated, isLoading, getIdTokenClaims, loginToBackend]);
  useEffect(() => {
    if (!isAuthenticated && !isLoading) {
      localStorage.setItem("auth", null);
      navigate("/");
    }
  }, [isAuthenticated, isLoading]);
  return (
    <div className="App">
      <RecoilRoot>
        <SWRConfig
          value={{
            fetcher: swrFetcher,
          }}
        >
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <Router>
              <Homepage path="/" />
              <AboutPage path="/about" />
              <FaqPage path="/faq" />
              <SingleListingPage path="/listings/single/:id" />
              <BrowseListingsPage path="/browse/*" />
              <EditListingPage path="/edit/:id" />
              {isAuthenticated && (
                <>
                  <UserDashboard path="/dashboard" />
                  {/* <Dashboard path="/dashboard" /> */}
                  <CreateListingPage path="/create" />
                </>
              )}

              {/* BY GeniusDev */}
              <InvoicePage path='/invoice' />
              <CheckoutPage path='/checkout' />
              <PaymentSuccessPage path='/payment-success' />
            </Router>
          </ThemeProvider>
        </SWRConfig>
      </RecoilRoot>
    </div>
  );
}

export default App;
