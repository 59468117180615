import React, { useState } from "react";
import styled from "@emotion/styled";
import { colors } from "theme";
import dayjs from "dayjs";
import { navigate } from "@reach/router";
import useRentalRequestsApi from "../../../../api/rental-requests";
import useRentalsApi from "../../../../api/rentals";
import SecondaryModal from "../../../../components/CommonModal/SecondaryModal";
import DatePicker from "./DatePicker";
import { Modal } from "@mui/material";

const RequestCardContainer = styled.div`
  padding: 10px;
  background-color: ${colors.offWhite};
  margin: 10px;
  box-shadow: 2px 2px 2px 1px #d9d9d9;
`;

const RequestDate = styled.div`
  font-size: 16px;
`;

const InfoLine = styled.div`
  font-size: 14px;
  text-align: left;
  padding: 3px;
  font-weight: ${(props) => (props.bold ? "bold" : "")};
`;
const Status = styled(InfoLine)`
  color: ${(props) =>
    props.status === "Accepted"
      ? "green"
      : props.status === "Declined"
      ? "red"
      : ""};
  font-weight: bold;
`;

const CardButton = styled.button`
  background-color: ${(props) => (props.color ? props.color : colors.navyBlue)};
  color: white;
  cursor: pointer;
  width: 150px;
  border-radius: 3px;
  padding: 3px;
  margin-top: 5px;
`;

const ModalButton = styled.button`
  background-color: ${(props) => (props.color ? props.color : colors.navyBlue)};
  color: white;
  cursor: pointer;
  width: 150px;
  border-radius: 3px;
  padding: 8px;
  margin-top: 10px;
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
  position: absolute;
  bottom: 15px;
`;

const ButtonGroup1 = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
  bottom: 15px;
`;

const ButtonContainer = styled.div`
  display: flex;
`;

const CreateRentalContainer = styled.div`
  position: relative;
  background: ${colors.offWhite};
  max-width: 500px;
  height: 525px;
  margin: auto;
  border: 1px solid #333;
  border-radius: 3px;
  margin-top: 50px;
`;

const ConfirmEndRequestContainer = styled.div`
  position: relative;
  background: ${colors.offWhite};
  max-width: 500px;
  margin: auto;
  border: 1px solid #333;
  border-radius: 3px;
  margin-top: 50px;
`;

const ModalHeader = styled.div`
  height: 50px;
  background: ${colors.blue};
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: center;
  color: white;
  font-size: 22px;
`;

const DatePickerInput = styled.div`
  margin-top: 15px;
  margin-bottom: 15px;
  display: flex;
  justify-content: center;
`;

const RequestCard = ({ request, sent, refresh, contact }) => {
  const [isCreateRentalModalOpen, setIsCreateRentalModalOpen] = useState(false);
  const [confirmEndRequestModalState, setConfirmEndRequestModalState] =
    useState(false);
  const [startDate, setStartDate] = useState(dayjs(new Date()));
  const [endDate, setEndDate] = useState(dayjs(new Date()));
  const {
    acceptRentalRequest,
    declineRentalRequest,
    deleteRentalRequest,
    endRentalRequest,
  } = useRentalRequestsApi();

  const { createNewRental } = useRentalsApi();

  const deleteRequest = async () => {
    try {
      await deleteRentalRequest(request.id);
      refresh();
    } catch (e) {
      console.log(e);
    }
  };

  const acceptRequest = async () => {
    try {
      // navigate(`/invoice`);
      await acceptRentalRequest(request.id);
      refresh();
    } catch (e) {
      console.log(e);
    }
  };

  const declineRequest = async () => {
    try {
      await declineRentalRequest(request.id);
      refresh();
    } catch (e) {
      console.log(e);
    }
  };

  const createRental = async () => {
    try {
      const data = {
        renter_user_id: request.created_by_user_id,
        listing_id: request.listing.id,
        rental_price: request.listing.unit_price,
        rental_total: getEstimatedRentalTotal().estimatedPrice,
        rental_time_unit: request.listing.unit_time,
        start_date_time: startDate,
        end_date_time: endDate,
      };
      await createNewRental(data);
      refresh();
      handleCloseModal();
    } catch (e) {
      console.log(e);
    }
  };

  const getEstimatedRentalTotal = () => {
    const date1 = dayjs(startDate);
    const date2 = dayjs(endDate);
    const diff = date2.diff(date1, request.listing.unit_time.toLowerCase());
    const estimatedPrice = diff * request.listing.unit_price;
    return { diff, estimatedPrice };
  };

  const handleCloseModal = () => {
    setIsCreateRentalModalOpen(false);
    setStartDate(dayjs(new Date()));
    setEndDate(dayjs(new Date()));
  };

  const endRequestModalHandler = async () => {
    await endRentalRequest(request.id);
    setConfirmEndRequestModalState(false);
  };

  return (
    <RequestCardContainer>
      <RequestDate>
        Created: {dayjs(request.created_at).format("dddd, MMM D, YY (h:mm a)")}
      </RequestDate>
      <Status status={request.status}>Status: {request.status}</Status>
      <InfoLine>Listing Name: {request?.listing?.name}</InfoLine>
      <InfoLine>Message: {request.message}</InfoLine>
      {contact && <InfoLine>Contact Name: {contact.name}</InfoLine>}
      {contact && <InfoLine>Contact Email: {contact.email}</InfoLine>}
      <ButtonContainer>
        <CardButton
          onClick={() => navigate(`/listings/single/${request?.listing.id}`)}
        >
          View Listing
        </CardButton>
      </ButtonContainer>
      {request.status !== "Pending" && (
        <ButtonContainer>
          <CardButton onClick={deleteRequest} color="red">
            Remove
          </CardButton>
        </ButtonContainer>
      )}
      {!sent && request.status === "Pending" && (
        <ButtonContainer>
          <CardButton onClick={acceptRequest} color="green">
            Accept
          </CardButton>
        </ButtonContainer>
      )}
      {!sent && request.status === "Pending" && (
        <ButtonContainer>
          <CardButton onClick={declineRequest} color="orange">
            Decline
          </CardButton>
        </ButtonContainer>
      )}
      {sent && request.status === "Accepted" && (
        <ButtonContainer>
          <CardButton
            onClick={() => {
              console.log(confirmEndRequestModalState);
              setConfirmEndRequestModalState(true);
            }}
            color="orange"
          >
            End Request
          </CardButton>
        </ButtonContainer>
      )}
      {!sent && request.status === "Accepted" && (
        <ButtonContainer>
          <CardButton
            onClick={() => setIsCreateRentalModalOpen(true)}
            color="green"
          >
            Create Rental
          </CardButton>
        </ButtonContainer>
      )}
      {sent && request.status === "Accepted" && (
        <Modal open={confirmEndRequestModalState}>
          <ConfirmEndRequestContainer>
            <ModalHeader>End Request</ModalHeader>
            <InfoLine bold>Do you want to end request?</InfoLine>
            <ButtonGroup1>
              <ButtonContainer>
                <ModalButton
                  onClick={() => setConfirmEndRequestModalState(false)}
                  color="orange"
                >
                  Cancel
                </ModalButton>
              </ButtonContainer>
              <ButtonContainer>
                <ModalButton
                  onClick={() => endRequestModalHandler()}
                  color="green"
                >
                  OK
                </ModalButton>
              </ButtonContainer>
            </ButtonGroup1>
          </ConfirmEndRequestContainer>
        </Modal>
      )}
      {!sent && request.status === "Accepted" && (
        <SecondaryModal open={isCreateRentalModalOpen}>
          <CreateRentalContainer>
            <ModalHeader>Create Rental</ModalHeader>
            <DatePickerInput>
              <DatePicker
                value={startDate}
                setValue={setStartDate}
                label="Start Time"
              />
            </DatePickerInput>
            <DatePickerInput>
              <DatePicker
                value={endDate}
                setValue={setEndDate}
                label="End Time"
              />
            </DatePickerInput>
            {contact && <InfoLine>Renter Name: {contact.name}</InfoLine>}
            {contact && <InfoLine>Renter Email: {contact.email}</InfoLine>}
            <InfoLine>
              Start Date: {dayjs(startDate).format("dddd, MMM D, YY (h:mm a)")}
            </InfoLine>
            <InfoLine>
              End Date: {dayjs(endDate).format("dddd, MMM D, YY (h:mm a)")}
            </InfoLine>
            <InfoLine>Rental Time Unit: {request.listing.unit_time}</InfoLine>
            <InfoLine>Rental Price: ${request.listing.unit_price}</InfoLine>
            <hr />
            <InfoLine bold>
              Estimated Rental Duration: {getEstimatedRentalTotal().diff}{" "}
              {request.listing.unit_time}(s)
            </InfoLine>
            <InfoLine bold>
              Estimated Total Price: $
              {getEstimatedRentalTotal().estimatedPrice.toFixed(2)}
            </InfoLine>
            <ButtonGroup>
              <ButtonContainer>
                <ModalButton onClick={() => handleCloseModal()} color="orange">
                  Cancel
                </ModalButton>
              </ButtonContainer>
              <ButtonContainer>
                <ModalButton
                  onClick={() => createRental()}
                  color="green"
                  disabled={getEstimatedRentalTotal().diff < 1}
                >
                  Create
                </ModalButton>
              </ButtonContainer>
            </ButtonGroup>
          </CreateRentalContainer>
        </SecondaryModal>
      )}
    </RequestCardContainer>
  );
};

export default RequestCard;
