export const ENDPOINTS = {
  //users
  USERS: "/users",
  USERS_ME: "/users/me",
  USERS_LOGIN: "/users/login",
  USERS_UPDATE: "/users/update",
  USERS_UPDATE_IMAGE: "/users/update-image",

  //listings
  LISTINGS: "/listings",
  LISTINGS_ME: "/listings/me",
  LISTINGS_GENERAL_SEARCH: "/listings/search",
  LISTINGS_HOME: "/listings/home",

  //contacts
  CONTACTS: "/contacts",

  //rent art assets
  RENT_ART_ASSETS: "/rent-art-assets",
  RENT_ART_ASSET_GENERATE_LINK: "/rent-art-assets/generate-link",
  RENT_ART_ASSET_CREATE: "/rent-art-assets/create",
  RENT_ART_ASSET_UPDATE_PRIMARY: "/rent-art-assets/update-primary-status",

  //rental requests
  RENTAL_REQUESTS: "/rental-requests",
  RENTAL_REQUESTS_ACCEPT: "/rental-requests/accept",
  RENTAL_REQUESTS_DECLINE: "/rental-requests/decline",

  // rentals
  RENTALS: "/rentals",
  RENTALS_RENTER_INFO: "/rentals/renter-info",

  //reviews
  REVIEWS: "/reviews",

  //saved listings
  SAVED_LISTINGS: "/saved-listings",
  TOGGLE_SAVED: "/saved-listings/toggle",

  //send feedback
  USER_FEEDBACK: "/user-feedback",

  //messages
  MESSAGES: "/messages",
  MARK_MESSAGES_READ: "/messages/mark-read",
  MESSAGES_UNREAD: "/messages/unread",

  QUOTE: "/messages/create-quote",

  //Payments
  CREATE_PAYMENT_INTENT: "/payment/createPaymentIntent",
};
