import React, { useState, useEffect } from "react";
import { Box, Grid, Select, MenuItem, InputLabel } from "@mui/material";
import { makeStyles } from "@mui/styles";
import PageWrapper from "../../components/PageWrapper";
import SearchBar from "./components/SearchBar";
import ListingCard from "../../components/ListingCard";
import useSWR from "swr";
import { ENDPOINTS } from "../../api/endpoints";
import { colors } from "theme";
import { useLocation } from "@reach/router";
import { parse } from "query-string";
import { SEARCH_CATEGORIES, SEARCH_TYPE } from "./components/static";

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: "100vh",
    marginTop: 200,
    backgroundColor: colors.lightYellow,
  },
  titleGroup: {
    display: "flex",
    alignItems: "center",
    marginBottom: 10,
  },
  title: {
    fontSize: 25,
    marginRight: 5,
  },
  titleSpan: {
    fontSize: 14,
  },
  filterSection: {
    textAlign: "left",
    padding: 20,
  },
  singleFilter: {
    paddingTop: 10,
  },
}));

const BrowseListingsPage = () => {
  const location = useLocation();
  const classes = useStyles();
  const searchParams = parse(location.search);
  const { error, data: fetchedListings } = useSWR(ENDPOINTS.LISTINGS_HOME);
  const [showSearchResults, setShowSearchResults] = useState(false);
  const [searchText, setSearchText] = useState(searchParams.search || "");
  const [category, setCategory] = useState(null);
  const [subCategory, setSubCategory] = useState(null);
  const [type, setType] = useState(null);
  const [query, setQuery] = useState("");

  const queryPrefix = !searchParams.search
    ? searchText === ""
      ? "?"
      : `?search=${searchText}&`
    : `?search=${searchParams.search}&`;

  const { data: results, mutate } = useSWR(
    `${ENDPOINTS.LISTINGS_GENERAL_SEARCH}${queryPrefix}${query}`
  );

  useEffect(() => {
    if (Boolean(searchParams.search)) {
      setShowSearchResults(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const generateQuery = () => {
    let stringToAppend = "";
    if (category) {
      stringToAppend += `category=${category}&`;
    }
    if (subCategory) {
      stringToAppend += `subcategory=${subCategory}&`;
    }
    if (type) {
      stringToAppend += `type=${type}&`;
    }
    setQuery(stringToAppend);
    mutate();
    setShowSearchResults(true);
  };

  useEffect(() => {
    if (searchText.trim() !== "") {
      mutate();
      setShowSearchResults(true);
    } else {
      setShowSearchResults(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  useEffect(() => {
    generateQuery();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category, subCategory, type]);

  const searchListings = async () => {
    setSearchText(searchText);
    generateQuery();
    setShowSearchResults(true);
  };

  const resetSearch = () => {
    setShowSearchResults(false);
    setSearchText("");
  };
  return (
    <PageWrapper isNavBarShowing>
      <Box className={classes.container}>
        <SearchBar
          value={searchText}
          onChange={setSearchText}
          searchListings={searchListings}
          resetSearch={resetSearch}
          showSearchResults={showSearchResults}
        />
        <Box className={classes.filterSection}>
          <Box className={classes.titleGroup}>
            <Box className={classes.title}>Filters</Box>
            <span className={classes.titleSpan}>
              - Click 'Search' after adjusting filters
            </span>
          </Box>

          <Box className={classes.singleFilter}>
            <InputLabel id="search-by-category">Category</InputLabel>
            <Select
              style={{ width: "150px" }}
              id="search-by-category"
              label="Category"
              variant="standard"
              value={category}
              onChange={(e) => setCategory(e.target.value)}
            >
              <MenuItem value={null}>None</MenuItem>
              {SEARCH_CATEGORIES.map((cat) => (
                <MenuItem key={cat} value={cat}>
                  {cat}
                </MenuItem>
              ))}
            </Select>
          </Box>
          <Box className={classes.singleFilter}>
            <InputLabel id="search-by-sub-category">Sub-Category</InputLabel>
            <Select
              style={{ width: "150px" }}
              id="search-by-sub-category"
              label="Sub-Category"
              variant="standard"
              value={subCategory}
              onChange={(e) => setSubCategory(e.target.value)}
            >
              <MenuItem value={null}>None</MenuItem>
              {SEARCH_CATEGORIES.map((cat) => (
                <MenuItem key={cat} value={cat}>
                  {cat}
                </MenuItem>
              ))}
            </Select>
          </Box>
          <Box className={classes.singleFilter}>
            <InputLabel id="search-by-type">Type</InputLabel>
            <Select
              style={{ width: "150px" }}
              id="search-by-type"
              label="Type"
              variant="standard"
              value={type}
              onChange={(e) => setType(e.target.value)}
            >
              <MenuItem value={null}>None</MenuItem>
              {SEARCH_TYPE.map((type) => (
                <MenuItem key={type.value} value={type.value}>
                  {type.name}
                </MenuItem>
              ))}
            </Select>
          </Box>
        </Box>
        {error && <Box>error</Box>}
        <Grid container>
          {!showSearchResults &&
            fetchedListings &&
            fetchedListings.map((listing, index) => (
              <ListingCard listing={listing} key={index + "small"} />
            ))}
          {showSearchResults &&
            results &&
            results.map((listing, index) => (
              <ListingCard listing={listing} key={index + "small"} />
            ))}
          {showSearchResults && results?.length === 0 && (
            <Box margin="auto" mt={2} fontSize={20}>
              No results for "{searchText}"
            </Box>
          )}
        </Grid>
      </Box>
    </PageWrapper>
  );
};

export default BrowseListingsPage;
