import React from "react";
import { Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { colors } from "../../theme";

const useStyles = makeStyles((theme) => ({
  container: {
    background: colors.offWhite,
    height: "fit-content",
    padding: 25,
    // width: (props) => (props.isMobile ? "95%" : "50%"),
    width: "100%",
    marginTop: 30,
  },
  location: {},
}));
const Map = (props) => {
  const { listing, isMobile } = props;
  const classes = useStyles({ isMobile });
  const locationParsed = listing.location_city.replace(/\s/g, "+");

  return (
    <Box className={classes.container}>
      <Box fontSize={18} mt={2}>
        Within 10 miles of <b>{listing.location_city}</b>
      </Box>
      <iframe
        title="map"
        width="100%"
        height="450"
        style={{ border: "1px solid #ccc" }}
        loading="lazy"
        allowFullScreen
        src={`https://www.google.com/maps/embed/v1/place?key=${process.env.REACT_APP_GOOGLE_MAPS_EMBED_API_KEY}&q=${locationParsed}`}
      ></iframe>
    </Box>
  );
};

export default Map;
