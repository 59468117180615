export const homePageImages = [
    {
        background: "backgroundImages/empty-garage-space-available.jpg",
        person: "rentArtPeopleBackgroundRemoved/person_carpenter-removebg-preview.png",
        mobilePerson: "rentArtBusts/person_dancer_bust.png"
    },
    {
        background: "backgroundImages/recording-studio-space.jpg",
        person: "rentArtPeopleBackgroundRemoved/person_composer-removebg-preview.png",
        mobilePerson: "rentArtBusts/person_composer_bust.png"
    },
    {
        background: "backgroundImages/Rehearsal-space-las-vegas.jpg",
        person: "rentArtPeopleBackgroundRemoved/person_dancer-removebg-preview.png",
        mobilePerson: "rentArtBusts/person_dancer_bust.png"
    },
    {
        background: "backgroundImages/digital-wacom-tablet-rental.jpg",
        person: "rentArtPeopleBackgroundRemoved/person_digital_artist-removebg-preview.png",
        mobilePerson: "rentArtBusts/person_painter_bust.png"
    },
    {
        background: "backgroundImages/Acoustic-guitar-for-rent.jpg",
        person: "rentArtPeopleBackgroundRemoved/person_guitar-removebg-preview.png",
        mobilePerson: "rentArtBusts/person_guitar_bust.png"
    },
    {
        background: "backgroundImages/printing-las-vegas.jpg",
        person: "rentArtPeopleBackgroundRemoved/person_painter-removebg-preview.png",
        mobilePerson: "rentArtBusts/person_painter_bust.png"
    },
    {
        background: "backgroundImages/sewing-machine-studio.jpg",
        person: "rentArtPeopleBackgroundRemoved/person_sewing-removebg-preview.png",
        mobilePerson: "rentArtBusts/person-sewing_bust.png"
    },
    {
        background: "backgroundImages/warehouse-space-rental.jpg",
        person: "rentArtPeopleBackgroundRemoved/person_tuba-removebg-preview.png",
        mobilePerson: "person_tuba_bust.png"
    },
    {
        background: "backgroundImages/welding-equipment-for-rent.jpg",
        person: "rentArtPeopleBackgroundRemoved/person_welder-removebg-preview.png",
        mobilePerson: "person_painter_bust.png"
    },
     {
        background: "backgroundImages/art-studio-space-las-vegas.jpg",
        person: "rentArtPeopleBackgroundRemoved/person-ceramics-2-removebg-preview.png",
        mobilePerson: "rentArtBusts/person_ceramics_bust.png"
    }
]