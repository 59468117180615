export const DASHBOARD_MENU_ITEMS = [
  "Profile",
  "Balance",
  "Listings",
  "Rental Management",
  "Contacts",
  "Messages",
];

export const RENTAL_TABS = [{ title: "Created" }, { title: "Received" }];

export const REQUEST_TABS = [{ title: "Sent" }, { title: "Received" }];
