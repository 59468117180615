import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import useSWR from "swr";
import { ENDPOINTS } from "../../../api/endpoints";
import { colors } from "theme";
import Chats from "./Chats";
import dayjs from "dayjs";
import { useForm } from "react-hook-form";
import useMessagesApi from "../../../api/messages";
import CreateQuoteModal from "./components/CreateQuoteModal";

const MessagesContainer = styled.div`
  padding: 30px;
  width: 100%;
`;

const SectionHeader = styled.div`
  font-size: 28px;
  text-align: left;
  margin-bottom: 10px;
  border-bottom: 1px solid #ddd;
`;

const ChatMessagesContainer = styled.div`
  border: 1px solid #ccc;
  background: colors.offWhite;
  color: black;
  max-height: 500px;
  overflow: auto;
`;

const ChatTitle = styled.div`
  background: ${colors.blue};
  color: ${colors.offWhite};
  padding: 10px;
  font-size: 20px;
`;

const TitleSpan = styled.span`
  font-size: 16px;
  float: left;
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

const ChatMessage = styled.div`
  display: flex;
  padding: 10px;
`;

const AuthorAvatar = styled.img`
  width: 50px;
  height: 50px;
  margin-right: 10px;
`;

const MessageDetails = styled.div`
  text-align: left;
`;

const MessageAuthor = styled.div`
  font-weight: bold;
  text-transform: capitalize;
  font-size: 16px;
  display: flex;
  align-items: center;
`;

const MessageSpan = styled.span`
  font-weight: normal;
  text-transform: none;
  font-size: 12px;
  margin-left: 10px;
`;

const ChatInputContainer = styled.div`
  display: flex;
`;

const ChatMessageInput = styled.input`
  width: 100%;
  font-size: 16px;
  padding: 5px 10px;
  height: 60px;
  border-color: #ccc;
  outline: none;
`;

const Button = styled.button`
  padding: 8px;
  width: 100px;
  font-size: 16px;
  color: white;
  border-color: ${colors.blue};
  background-color: ${colors.blue};
  cursor: pointer;
`;

const ButtonContainer = styled.div`
  display: flex;
`;

const SForm = styled.form``;

const Messages = ({ isMobile }) => {
  const [messages, setMessages] = useState({});
  const [currentUserId, setCurrentUserId] = useState(null);
  const [productInfo, setProductInfo] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedChat, setSelectedChat] = useState(null);
  const { data, mutate: refreshMessages } = useSWR(ENDPOINTS.MESSAGES);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const { sendMessage, sendQuote } = useMessagesApi();

  useEffect(() => {
    const id = localStorage.getItem("user_id");
    setCurrentUserId(id);
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({});

  useEffect(() => {
    if (data) {
      setMessages(data);
      console.log(data);
    }
  }, [data]);

  useEffect(() => {
    messages && selectedUser && selectedChat && setProductInfo(messages[selectedUser][selectedChat][0].listing);
  }, [messages, selectedUser, selectedChat]);

  const getSortedMessages = () => {
    const sorted = messages[selectedUser][selectedChat].sort((a, b) => {
      return new Date(a.message.created_at) - new Date(b.message.created_at);
    });
    return sorted;
  };

  const sendNewMessage = async (data) => {
    console.log("sending message", data);
    try {
      const messageData = {
        receiver_id: selectedUser,
        message: data.newMessage,
        listing_id: messages[selectedUser][selectedChat][0].listing.listing_id,
      };
      await sendMessage(messageData);
      setValue("newMessage", "");
      refreshMessages();
    } catch (error) {
      console.log(error);
    }
  };

  const goBack = () => {
    setSelectedChat(null);
    refreshMessages();
  };

  const quoteHandler = async (data) => {
    try {
      const messageData = {
        receiver_id: selectedUser,
        message: "Quote Message",
        listing_id: messages[selectedUser][selectedChat][0].listing.listing_id,
        ...data
      };
      await sendQuote(messageData);
      setValue("newMessage", "");
      refreshMessages();
    } catch (error) {
      console.log(error);
    }
    setIsCreateModalOpen(false);
  }

  return (
    <MessagesContainer>
      <SectionHeader>Messages</SectionHeader>
      {!selectedChat && (
        <Chats
          messages={messages}
          selectedChat={selectedChat}
          setSelectedChat={setSelectedChat}
          setSelectedUser={setSelectedUser}
          selectedUser={selectedUser}
          refresh={refreshMessages}
        />
      )}

      {selectedChat && (
        <>
          <ChatTitle>
            <TitleSpan onClick={() => goBack()}>&larr; Back</TitleSpan>
            {messages[selectedUser][selectedChat][0].listing.listing_name}
          </ChatTitle>
          <ChatMessagesContainer>
            {getSortedMessages().map((message) => {
              return (
                <ChatMessage key={message.message.id}>
                  <AuthorAvatar src={message.message.sender_avatar_url} />
                  <MessageDetails>
                    <MessageAuthor>
                      {message.message.sender_name}
                      <MessageSpan>
                        {dayjs(message.message.created_at).format(
                          "MMM D, YY (h:mm a)"
                        )}
                      </MessageSpan>
                    </MessageAuthor>
                    <div>{message.message.content}</div>
                  </MessageDetails>
                </ChatMessage>
              );
            })}
          </ChatMessagesContainer>
          <SForm onSubmit={handleSubmit(sendNewMessage)}>
            <ChatInputContainer>
              <ChatMessageInput
                type="text"
                name="newMessage"
                placeholder="Send A Message..."
                error={errors.newMessage}
                {...register("newMessage", { required: true })}
              />
              <ButtonContainer>
                <Button type="submit">Send</Button>
                {
                  productInfo && currentUserId && (productInfo.listing_owner_id !== currentUserId) ?
                    <>
                      <Button type="button" onClick={() => setIsCreateModalOpen(true)}>Send Quote</Button>
                      <CreateQuoteModal
                        modalOpen={isCreateModalOpen}
                        handleClose={() => setIsCreateModalOpen(false)}
                        quoteHandler={quoteHandler}
                        productInfo={productInfo}
                      />
                    </>
                    :
                    <></>
                }
              </ButtonContainer>
            </ChatInputContainer>
          </SForm>
        </>
      )}
    </MessagesContainer>
  );
};

export default Messages;
