import { useState } from "react";
import { OutlinedInput, InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { navigate } from "@reach/router";

export const SearchBox: React.FC = () => {
  const [searchInput, setSearchInput] = useState("");
  const handleKeyDown = (event: any) => {
    if (event.key === "Enter") {
      const searchParams = `search=${searchInput}`;
      if (searchInput.trim() === "") {
        navigate(`/browse`);
      } else {
        navigate(`/browse?${searchParams}`);
      }
    }
  };

  return (
    <OutlinedInput
      endAdornment={
        <InputAdornment position="end" sx={{ color: "rentArt.blue" }}>
          <SearchIcon sx={{ height: "3rem", width: "3rem" }} />
        </InputAdornment>
      }
      sx={{ maxWidth: "30rem", margin: "1rem 0" }}
      value={searchInput}
      onChange={(e) => setSearchInput(e.target.value)}
      onKeyDown={handleKeyDown}
      fullWidth
    />
  );
};
