import React, { useState } from "react";
import styled from "@emotion/styled";
import DesktopMenu from "./DesktopMenu";
import PageWrapper from "../../components/PageWrapper";
import Profile from "./Profile";
import MobileMenu from "./MobileMenu";
import { useMediaQuery } from "@mui/material";
import Listings from "./Listings/index";
import RentalManagement from "./RentalManagement";
import Contacts from "./Contacts";
import Messages from "./Messages";
import Balance from "./Balance";
// import BalanceManagement from "./BalanceManagement";

const Container = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: ${(props) => (props.isMobile ? "column" : "row")};
`;

const UserDashboard = () => {
  const isMobile = useMediaQuery("(max-width:800px)");
  const [activeTab, setActiveTab] = useState("Profile");
  const determineComponent = () => {
    console.log(activeTab);
    switch (activeTab) {
      case "Profile":
        return <Profile isMobile={isMobile} />;
      case "Balance":
        return <Balance isMobile={isMobile} />
      case "Listings":
        return <Listings isMobile={isMobile} />;
      case "Rental Management":
        return <RentalManagement isMobile={isMobile} />;
      case "Contacts":
        return <Contacts isMobile={isMobile} />;
      case "Messages":
        return <Messages isMobile={isMobile} />;
      default:
        return "";
    }
  };
  return (
    <PageWrapper isNavBarShowing isFullWidth>
      <Container isMobile={isMobile}>
        {isMobile && (
          <MobileMenu activeTab={activeTab} setActiveTab={setActiveTab} />
        )}
        {!isMobile && (
          <DesktopMenu activeTab={activeTab} setActiveTab={setActiveTab} />
        )}
        {determineComponent()}
      </Container>
    </PageWrapper>
  );
};

export default UserDashboard;
