import { useEffect, useCallback, useState } from "react";
import { Box, useMediaQuery } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import NavBarContainer from "../NavigationBar/NavBarContainer";
import Footer from "../Footer/index";
import AgreeTermsAndConditions from "./AgreeTermsAndConditions";

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: (props) => (props.isFullWidth ? "100%" : 1500),
    width: "100%",
    margin: "auto",
    marginTop: 80,
  },
}));

const PageWrapper = ({ isNavBarShowing, children, isFullWidth }) => {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const [y, setY] = useState(window.scrollY);
  const [showLogo, setShowLogo] = useState(true);

  const handleNavigation = useCallback(
    (e) => {
      const window = e.currentTarget;
      if (y > 10) {
        setShowLogo(false);
      } else if (y <= 10) {
        setShowLogo(true);
      }
      setY(window.scrollY);
    },
    [y]
  );

  useEffect(() => {
    setY(window.scrollY);
    window.addEventListener("scroll", handleNavigation);

    return () => {
      window.removeEventListener("scroll", handleNavigation);
    };
  }, [handleNavigation]);

  const isMobile = useMediaQuery("(min-width:800px)");
  const classes = useStyles({ isFullWidth, isMobile });

  return (
    <Box>
      {isNavBarShowing && <NavBarContainer showLogo={showLogo} />}
      <Box className={classes.container}>{children}</Box>

      <AgreeTermsAndConditions isFullWidth={isFullWidth} />

      <Footer />
    </Box>
  );
};

export default PageWrapper;
