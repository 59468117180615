import styled from "@emotion/styled";
import { colors } from "theme";

export const CardContainer = styled.div`
  background-color: ${colors.fadedBlue};
  padding-bottom: 10px;
  margin: 10px;
  box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgba(0, 0, 0, 0);
  transition: background-color 0.3s ease;
  border-radius: 5px;
`;

export const CardImage = styled.img`
  width: 100%;
  height: 300px;
  border-radius: 5px;
  cursor: pointer;
`;

export const Title = styled.div`
  font-size: 26px;
  text-transform: capitalize;
`;

export const Category = styled.div`
  font-size: 12px;
`;

export const Price = styled.div`
  font-size: 22px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  gap: 8px;
  justify-content: center;
`;
export const CardButton = styled.button`
  padding: 8px;
  cursor: pointer;
  color: white;
  background: ${colors.blue};
  &:hover {
    scale: 1.05;
  }
`;

export const CardError = styled.div`
  color: red;
  font-size: 16px;
  text-align: center;
`;
