import axios from "axios";
import { ENDPOINTS } from "../endpoints";

const useImageUploadApi = () => {
  const getAccessToken = () => {
    const { id_token: accessToken } =
      JSON.parse(localStorage.getItem("auth")) || {};
    return accessToken;
  };
  const uploadFile = async (file, listingId, user) => {
    const token = getAccessToken();
    try {
      const url = await axios({
        method: "post",
        url: `${process.env.REACT_APP_SERVER_BASE_URL}${ENDPOINTS.RENT_ART_ASSET_GENERATE_LINK}`,
        data: {
          filename: file.name,
          listing_id: listingId,
          file_size: file.size,
        },
        headers: {
          authorization: `Bearer ${token}`,
        },
      });
      const axiosResponse = await axios({
        method: "put",
        url: url.data.s3UploadUrl,
        headers: {
          "Content-Type": "image/jpeg",
        },
        data: file,
      });
      if (axiosResponse.status === 200) {
        const reference = `${process.env.REACT_APP_LISTING_ASSETS_S3}/${user.internal_user_id}/${listingId}/${file.name}`;
        return reference;
      }
    } catch (e) {
      console.error(e);
      return e;
    }
  };

  const saveImageToListing = async (
    listingId,
    urlReference,
    isPrimary = false
  ) => {
    const token = getAccessToken();
    const data = {
      asset_url: urlReference,
      is_primary: isPrimary,
      listing_id: listingId,
    };
    try {
      await axios({
        method: "post",
        url: `${process.env.REACT_APP_SERVER_BASE_URL}/rent-art-assets/create`,
        data,
        headers: {
          authorization: `Bearer ${token}`,
        },
      })
        .then((resp) => {
          return "Success";
        })
        .catch((e) => {
          return "Error";
        });
    } catch (e) {
      console.error(e);
      return "Error";
    }
  };

  return {
    uploadFile,
    saveImageToListing,
  };
};

export { useImageUploadApi };
