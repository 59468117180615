import React from "react";
import styled from "@emotion/styled";
import { colors } from "theme";
import useSWR from "swr";
import { ENDPOINTS } from "../../../api/endpoints";
import ListingCard from "../../../components/ListingCard";
import { Grid } from "@mui/material";
import { navigate } from "@reach/router";

const ListingsContainer = styled.div`
  padding: ${(props) => (props.isMobile ? "10px" : "30px")};
  width: 100%;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: ${(props) => (props.isMobile ? "center" : "flex-start")};
`;

const CreateListingButton = styled.button`
  font-size: 30px;
  padding: 5px;
  width: 200px;
  background: ${colors.blue};
  border-radius: 5px;
  color: white;
  margin: 10px;
  cursor: pointer;
`;

const SectionHeader = styled.div`
  font-size: 28px;
  text-align: left;
  margin: 10px;
  border-bottom: 1px solid #ddd;
`;

const Listings = ({ isMobile }) => {
  const { data: listings, mutate: refresh } = useSWR(ENDPOINTS.LISTINGS_ME);
  const { data: savedListings } = useSWR(ENDPOINTS.SAVED_LISTINGS);
  return (
    <ListingsContainer isMobile={isMobile}>
      <ButtonContainer isMobile={isMobile}>
        <CreateListingButton onClick={() => navigate("/create")}>
          Create +
        </CreateListingButton>
      </ButtonContainer>
      <SectionHeader>My Listings</SectionHeader>
      <Grid container>
        {listings?.map((listing) => (
          <ListingCard
            listing={listing}
            key={listing.id}
            owner
            refresh={refresh}
            showButtons
          />
        ))}
      </Grid>
      <SectionHeader>Favorites</SectionHeader>
      <Grid container>
        {savedListings?.map((listing) => (
          <ListingCard listing={listing.listing} key={listing.id} />
        ))}
      </Grid>
    </ListingsContainer>
  );
};

export default Listings;
