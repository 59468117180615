// import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/material";
import CreateListingPage from "./CreateListingPage";
// import Step1 from "./Steps/Step1";
// import Step2 from "./Steps/Step2";
// import Step3 from "./Steps/Step3";
// import Step4 from "./Steps/Step4";
// import Step5 from "./Steps/Step5";
// import Step6 from "./Steps/Step6";
// import CommonButton from "../../components/CommonButton";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 10,
  },
  header: {},
}));

const Refactor = (props) => {
  // const [isReviewReady, setIsReviewReady] = useState(false);
  // const {} = props;
  const classes = useStyles();
  return (
    <Box className={classes.container}>
      <CreateListingPage />
      {/* <Step1 />
      <Step2 />
      <Step3 />
      <Step4 />
      <Step5 />
      <Step6 /> */}
      {/* {!isReviewReady && (
        <Box textAlign="center">
          <CommonButton
            text="Ready to review"
            buttonVariant="coolBlue"
            isUppercase
            handleClick={() => {
              setIsReviewReady(true);
            }}
          />
        </Box>
      )} */}
      {/* text, customStyles, handleClick, buttonVariant, isUppercase, isFullWidth,
      disabled, */}
    </Box>
  );
};

export default Refactor;
