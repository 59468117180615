import { makeStyles } from "@mui/styles";
import { Box } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  labelText: {
    fontWeight: "bold",
    textAlign: "left",
  },
  selectContainer: {
    width: "fit-content",
    display: "flex",
    flexDirection: "column",
    paddingBottom: 10,
  },
}));

const CreateListingSelect = (props) => {
  const { name, required, options, label, error, register } = props;
  const classes = useStyles();
  return (
    <Box className={classes.selectContainer}>
      <Box className={classes.labelText}>
        {label}
        {required ? "*" : ""}
      </Box>
      <select
        {...register(name, { required: required })}
        style={{ padding: 5, borderColor: error ? "red" : "" }}
      >
        <option value="">Select...</option>
        {options.map((category) => (
          <option key={category} value={category}>
            {category}
          </option>
        ))}
      </select>
    </Box>
  );
};

export default CreateListingSelect;
