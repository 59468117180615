import React from "react";
import styled from "@emotion/styled";
import { colors } from "theme";
import dayjs from "dayjs";
import { navigate } from "@reach/router";
import { ColorSpan } from "../styled";
import useRentalsApi from "../../../../api/rentals";

const RentalCardContainer = styled.div`
  padding: 10px;
  background-color: ${colors.offWhite};
  margin: 10px;
  box-shadow: 2px 2px 2px 1px #d9d9d9;
`;

const InfoLine = styled.div`
  font-size: 14px;
  text-align: left;
  padding: 3px;
  font-weight: ${(props) => (props.bold ? "bold" : "")};
`;

const CardButton = styled.button`
  background-color: ${(props) => (props.color ? props.color : colors.navyBlue)};
  color: white;
  cursor: pointer;
  width: 150px;
  border-radius: 3px;
  padding: 3px;
  margin-top: 5px;
`;

const ButtonContainer = styled.div`
  display: flex;
`;

const RentalCard = ({ rental, created, refresh }) => {
  const { deleteRental } = useRentalsApi();

  const remove = async () => {
    try {
      await deleteRental(rental.id);
      refresh();
    } catch (e) {
      console.log(e);
    }
  };

  const getRentalDuration = () => {
    const date1 = dayjs(rental.start_date_time);
    const date2 = dayjs(rental.end_date_time);
    const duration = date2.diff(date1, rental.rental_time_unit.toLowerCase());
    const estimatedPrice = duration * rental.rental_price;
    return { duration, estimatedPrice };
  };

  const { duration, estimatedPrice } = getRentalDuration();

  return (
    <RentalCardContainer>
      <InfoLine>Listing Name: {rental?.listing?.name}</InfoLine>
      <InfoLine>
        Start:{" "}
        {dayjs(rental.start_date_time).format("dddd, MMM D, YY (h:mm a)")}
      </InfoLine>
      <InfoLine>
        End: {dayjs(rental.end_date_time).format("dddd, MMM D, YY (h:mm a)")}
      </InfoLine>
      <InfoLine>
        Price Per {rental.rental_time_unit}: ${rental.rental_price}
      </InfoLine>
      <InfoLine bold>
        Estimated Duration: {duration} {rental.rental_time_unit}
        (s)
      </InfoLine>
      <InfoLine bold>
        Estimated Price: <ColorSpan color="green">${estimatedPrice}</ColorSpan>
      </InfoLine>
      <InfoLine bold>
        Status:&nbsp;
        {
          rental.status === "Paid" && <ColorSpan color="green">Paid</ColorSpan>
        }
        {
          rental.status === "NotPaid" && <ColorSpan color="red">Not Paid</ColorSpan>
        }
        {
          rental.status === "Declined" && <ColorSpan color="orange">Declined</ColorSpan>
        }
      </InfoLine>
      <hr />
      <ButtonContainer>
        <CardButton
          onClick={() => navigate(`/listings/single/${rental?.listing.id}`)}
        >
          View Listing
        </CardButton>
      </ButtonContainer>
      <ButtonContainer>
        <CardButton
          onClick={() => {
            localStorage.setItem("curr_rental", JSON.stringify(rental));
            navigate(`/invoice`);
          }}
        >
          View Invoice
        </CardButton>
      </ButtonContainer>
      {created && (
        <ButtonContainer>
          <CardButton onClick={remove} color={colors.deepRed}>
            Remove
          </CardButton>
        </ButtonContainer>
      )}
    </RentalCardContainer>
  );
};

export default RentalCard;
