import { atom, useRecoilState } from "recoil";

const listingPriceState = atom({
  key: "listingPriceState",
  default: "",
});

const listingUnitState = atom({
  key: "listingUnitState",
  default: "",
});

const listingTypeState = atom({
  key: "listingTypeState",
  default: "",
});

const minTimeState = atom({
  key: "minTimeState",
  default: "",
});

const maxTimeState = atom({
  key: "maxTimeState",
  default: "",
});

const priceDescriptionState = atom({
  key: "priceDescription",
  default: "",
});

const useStep3State = () => {
  const [listingUnit, setListingUnit] = useRecoilState(listingUnitState);
  const [listingPrice, setListingPrice] = useRecoilState(listingPriceState);
  const [listingType, setListingType] = useRecoilState(listingTypeState);
  const [minTime, setMinTime] = useRecoilState(minTimeState);
  const [maxTime, setMaxTime] = useRecoilState(maxTimeState);
  const [priceDescription, setPriceDescription] = useRecoilState(
    priceDescriptionState
  );

  const handleListingPriceChange = (value) => {
    setListingPrice(value.value);
  };
  const handleListingUnitChange = (value) => {
    setListingUnit(value);
  };

  const handleListingTypeChange = (value) => {
    setListingType(value);
  };

  const handleMinTimeChange = (value) => {
    setMinTime(value);
  };

  const handleMaxTimeChange = (value) => {
    setMaxTime(value);
  };

  const handlePriceDescriptionChange = (value) => {
    setPriceDescription(value);
  };

  const clearStep3 = () => {
    setListingPrice("");
    setListingUnit("");
    setListingType("");
    setMinTime("");
    setMaxTime("");
    setPriceDescription("");
  };

  return {
    listingPrice,
    listingUnit,
    listingType,
    minTime,
    maxTime,
    priceDescription,
    handleMaxTimeChange,
    handleMinTimeChange,
    handleListingTypeChange,
    setListingPrice,
    setListingUnit,
    handleListingPriceChange,
    handleListingUnitChange,
    handlePriceDescriptionChange,
    clearStep3,
  };
};

export { useStep3State };
