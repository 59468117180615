import React, { useState } from "react";
import useSWR from "swr";
import { ENDPOINTS } from "../../../api/endpoints";
import SimpleImageCropper from "./ImageCropper/index";
import ProfileInfo from "./ProfileInfo";
import {
  CoverImageHolder,
  EditImageOverlay,
  ImageContainer,
  ProfileContainer,
  ProfileImage,
} from "./styled";

const Profile = ({ isMobile }) => {
  const { data: me, mutate } = useSWR(ENDPOINTS.USERS_ME);
  const [image, setImage] = useState(null);
  const [imageName, setImageName] = useState(null);
  const [imageType, setImageType] = useState(null);

  const acceptableImageTypes = ["image/jpeg", "image/png"];

  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    if (file && acceptableImageTypes.includes(file.type)) {
      setImage(URL.createObjectURL(file));
      setImageName(file.name);
      setImageType(file.type);
      e.target.value = null;
    } else {
      console.log("File Selection Aborted");
    }
  };

  const handleClose = () => {
    setImage(null);
    setImageName(null);
    setImageType(null);
  };

  return (
    <ProfileContainer isMobile={isMobile}>
      <ImageContainer isMobile={isMobile}>
        <ProfileImage src={me?.avatar_url} />
        <EditImageOverlay>
          <CoverImageHolder>
            Edit Image
            <input
              type="file"
              name="cover"
              onChange={handleImageUpload}
              accept="image/jpeg, image/png"
              style={{ display: "none" }}
            />
          </CoverImageHolder>
        </EditImageOverlay>
      </ImageContainer>
      <SimpleImageCropper
        isMobile={isMobile}
        user={me}
        image={image}
        imageName={imageName}
        imageType={imageType}
        handleClose={() => handleClose()}
        refresh={mutate}
      />
      {me && <ProfileInfo user={me} refresh={mutate} isMobile={isMobile} />}
    </ProfileContainer>
  );
};

export default Profile;
