export const PUBLIC_NAV_ITEMS = [
  {
    title: "Home",
    link: "/",
    action: "",
  },
  {
    title: "About",
    link: "/about",
    action: "",
  },
  {
    title: "FAQ",
    link: "/faq",
    action: "",
  },
  {
    title: "Browse",
    link: "/browse",
    action: "",
  },
];

export const AUTHENTICATED_NAV_ITEMS = [
  {
    title: "Dashboard",
    link: "/dashboard",
    action: "",
  },
    {
    title: "Create",
    link: "/create",
    action: "",
  },
]

