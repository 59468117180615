import React from "react";
import styled from "@emotion/styled";
import { colors } from "theme";

const CardContainer = styled.div`
  padding: 10px;
  background-color: ${colors.offWhite};
  box-shadow: 2px 2px 2px 1px #d9d9d9;
`;
const CardTitle = styled.div`
  color: ${colors.navyBlue};
  font-weight: bold;
  text-transform: capitalize;
`;
const CardInfo = styled.div`
  color: ${colors.blueGrotto};
`;

const CardAvatar = styled.img`
  width: 50%;
`;

const ContactCard = ({ contact, isMobile }) => {
  return (
    <CardContainer isMobile={isMobile}>
      <CardAvatar src={contact.avatar} />
      <CardTitle>{contact.name}</CardTitle>
      <CardInfo>{contact.email}</CardInfo>
      <CardInfo>{contact.location}</CardInfo>
    </CardContainer>
  );
};

export default ContactCard;
