import React from 'react'
import PageWrapper from '../../components/PageWrapper'
import { Box, useMediaQuery } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import useSWR from 'swr'
import { ENDPOINTS } from '../../api/endpoints'
import ImageSection from './ImageSection'
import Map from './Map'
import TitleSection from './TitleSection'
import { colors } from '../../theme'
import ReviewSection from './ReviewSection'
// import Reviews from "./Reviews";

const useStyles = makeStyles(theme => ({
  container: {
    minHeight: '100vh',
    backgroundColor: colors.lightYellow
  },
  header: {
    paddingTop: 20,
    fontSize: 24
  },
  listing: {
    padding: 10
  },
  topSection: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  bottomSection: {
    display: 'flex'
  },
  reviewSection: {
    margin: 20,
    marginTop: 50
  }
}))

const SingleListingPage = props => {
  const isMobile = useMediaQuery('(max-width:800px)')
  const { data: listing, error } = useSWR(
    `${ENDPOINTS.LISTINGS}/single/${props.id}`
  )

  const classes = useStyles({ isMobile })
  // need to create a common error message component
  // need to create a way to show how many people have saved this listing, and number of views === future feature.
  return (
    <PageWrapper isNavBarShowing>
      <Box className={classes.container}>
        {!error && listing && (
          <Box className={classes.listing}>
            <Box className={classes.topSection}>
              <ImageSection listing={listing} isMobile={isMobile} />
              <TitleSection listing={listing} isMobile={isMobile} />
            </Box>
            <Box className={classes.reviewSection}>
              <ReviewSection
                listing={listing}
                isMobile={isMobile}
              ></ReviewSection>
            </Box>
            <Box className={classes.bottomSection}>
              <Map listing={listing} isMobile={isMobile} />
              {/* <Reviews listing={listing} isMobile={isMobile} /> */}
            </Box>
          </Box>
        )}
        {error && <Box>Something went wrong... Please contact support.</Box>}
      </Box>
    </PageWrapper>
  )
}

export default SingleListingPage
