import { ENDPOINTS } from "../endpoints";
import { createApi } from "../setup";

const useListingsApi = () => {
  const createListing = async (data) => {
    const API = await createApi();
    const listings = await API.post(ENDPOINTS.LISTINGS, data).then((resp) => {
      return resp.data;
    });
    return listings;
  };

  const deleteListing = async (listingId) => {
    const API = await createApi();
    await API.delete(`${ENDPOINTS.LISTINGS}/${listingId}`).then((resp) => {
      return resp.data;
    });
  };

  const updateListing = async (listingId, data) => {
    const API = await createApi();
    await API.put(`${ENDPOINTS.LISTINGS}/${listingId}`, data)
      .then((resp) => {
        return resp.data;
      })
      .catch((error) => {
        console.log(error);
        return error;
      });
  };

  const generalSearch = async (searchText) => {
    const API = await createApi();
    await API.get(`${ENDPOINTS.LISTINGS_GENERAL_SEARCH}/?search=${searchText}`)
      .then((resp) => {
        return resp.data;
      })
      .catch((error) => {
        console.log(error);
        return [];
      });
  };

  return {
    createListing,
    deleteListing,
    updateListing,
    generalSearch,
  };
};

export default useListingsApi;
