import React, {useEffect} from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./components/CheckoutForm";
import paymentsApi from "../../api/payments";
import { CircularProgress } from "@mui/material";
// import CheckoutForm from "./components/CheckoutForm";
// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is your test publishable API key.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

export default function CheckoutPage() {
    const [clientSecret, setClientSecret] = React.useState("");
    const [isLoad, setIsLoad] = React.useState(true);
    const [error, setError] = React.useState("");
    const [rental, setRental] = React.useState(null);
    const [amount, setAmount] = React.useState(0.0);
    const loadingStyle = {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        width: '100%',

    };
    useEffect(() => {
        const tmp = localStorage.getItem('curr_rental');
        setRental(JSON.parse(tmp));
        createPayment();
    }, []);

    async function createPayment() {
        // Create PaymentIntent as soon as the page loads
        try {
            setIsLoad(true);
            setError("");
            const customerId = localStorage.getItem('customerId');
            const amount = localStorage.getItem('amount');
            setAmount(amount);
            const res = await paymentsApi().createPaymentIntent({
                customerId: customerId,
                amount: Number(amount),
            })
            console.log("************* Created Payment Intent  ************", res);
            // const data = JSON.parse(res.data);
            setClientSecret(res.data.client_secret);
            setIsLoad(false);
        } catch (error) {
            console.error("************* Created Payment Intent  ************", error);
            setIsLoad(false);
            setError(error + "");
        }
    }

    const appearance = {
        theme: 'stripe',
    };

    const options = {
        clientSecret,
        appearance,
    };

    return (
        (error !== "") ? <h4 style={{ color: "red" }}>{error}</h4> : (!isLoad) ?
            <>
                <div className="CheckoutPage">
                    {clientSecret && (
                        < Elements options={options} stripe={stripePromise} >
                            < CheckoutForm amount={amount} rental={rental} />
                        </Elements >
                    )}
                </div >

            </>
            :
            <center style={loadingStyle}>
                < CircularProgress />
            </center>
    );
}