import styled from "@emotion/styled";

export const BalanceContainer = styled.div`
  padding: ${(props) => (props.isMobile ? "10px" : "30px")};
  width: 100%;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const OutcomeSpan = styled.div`
  color: red;
  font-weight: 700;
`;

export const IncomeSpan = styled.div`
  color: green;
  font-weight: 700;
`;