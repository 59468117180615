import React, { useEffect } from "react";
import { Box } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import ImageUploader from "react-images-upload";
import { useEditListingState } from "state/CustomHooks";
import { colors } from "theme";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 10,
    backgroundColor: colors.lightYellow,
  },
}));
const AddImageUploader = () => {
  const classes = useStyles();
  const { handleAssetChange, assets } = useEditListingState();

  useEffect(() => {
    onDrop(assets);
  });

  const onDrop = (picture) => {
    handleAssetChange(picture);
  };

  return (
    <Box className={classes.container}>
      <ImageUploader
        withIcon={true}
        buttonText="Choose images"
        onChange={onDrop}
        withPreview
        label={"Max File Size 5MB."}
        maxFileSize={5242880}
      />
      {assets.length > 0 && (
        <Box>
          <Box component="h3">Selected Images</Box>
          {assets.map((image, index) => (
            <Box textAlign="left" p={1} key={image.name + index}>
              {index + 1}. {image.name}
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
};

export default AddImageUploader;
