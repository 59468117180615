import React from "react";
import styled from "@emotion/styled";
import { colors } from "theme";
import { DASHBOARD_MENU_ITEMS } from "./_static";
import useSWR from "swr";
import { ENDPOINTS } from "../../api/endpoints";

const DesktopMenuContainer = styled.div`
  background-color: ${colors.fadedBlue};
  width: 300px;
  padding-top: 80px;
`;

const MenuItem = styled.div`
  cursor: pointer;
  font-size: 20px;
  border-bottom: 1px solid #ccc;
  color: black;
  text-align: left;
  padding: 5px;
  background-color: ${(props) => (props.active ? colors.blue : "")};
  &:hover {
    background-color: ${colors.blue};
  }
`;

const UnreadCount = styled.span`
  font-size: 12px;
  background: ${colors.pink};
  color: ${colors.offWhite};
  padding: 2px;
  border-radius: 5%;
  margin-left: 3px;
`;

const DesktopMenu = ({ activeTab, setActiveTab }) => {
  const { data, mutate: refreshMessages } = useSWR(ENDPOINTS.MESSAGES_UNREAD);
  const handleChangeTab = (tab) => {
    setActiveTab(tab);
    refreshMessages();
  };
  return (
    <DesktopMenuContainer>
      {DASHBOARD_MENU_ITEMS.map((item, index) => {
        return (
          <MenuItem
            key={index + "desktopMenuItem"}
            active={activeTab === item}
            onClick={() => handleChangeTab(item)}
          >
            {item === "Messages" && data?.unread_count !== 0 ? (
              <div>
                {item} <UnreadCount>{data?.unread_count} unread</UnreadCount>
              </div>
            ) : (
              item
            )}
          </MenuItem>
        );
      })}
    </DesktopMenuContainer>
  );
};

export default DesktopMenu;
