import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { colors } from "theme";
import { useForm } from "react-hook-form";
import useUsersApi from "../../../../api/users";

const ProfileInfoContainer = styled.div``;

const InputLabel = styled.label`
  font-size: 16px;
  display: flex;
  flex-direction: column;
  text-align: left;
  padding-top: 10px;
`;
const ProfileInput = styled.input`
  font-size: 16px;
  padding: 8px;
  outline: none;
  border: 1px solid ${(props) => (props.error ? "orange" : "#666")};
  width: 100%;
  border-radius: 3px;
`;

const InputErrorMessage = styled.div`
  font-size: 14px;
  color: orange;
`;

const SaveProfileButton = styled.button`
  font-size: 18px;
  background: ${(props) =>
    props.disabled ? colors.disabledBackground : colors.blue};
  outline: none;
  border: 1px solid #666;
  padding: 6px;
  margin-top: 15px;
  color: white;
  width: 100%;
  border-radius: 3px;
`;

const LoadingMessage = styled.div`
  font-size: 18px;
`;

const ErrorMessage = styled.div`
  font-size: 18px;
`;

const ProfileInfo = ({ user, refresh }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [canSave, setCanSave] = useState(false);
  const { editUser } = useUsersApi();
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: {
      name: user?.name,
      email: user?.email,
      phone: user?.phone,
      location: user?.location_city,
    },
  });

  const formHasChanges = (data) => {
    return user.name !== data.name ||
      user.phone !== data.phone ||
      user.location !== data.location
      ? true
      : false;
  };

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (error) setError(false);
      if (formHasChanges(value)) {
        setCanSave(true);
      } else {
        setCanSave(false);
      }
    });
    return () => subscription.unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch]);

  const handleSaveProfile = async (data) => {
    try {
      const userData = {
        name: data.name,
        phone: data.phone,
        location_city: data.location,
      };
      setLoading(true);
      await editUser(userData);
      refresh();
      setCanSave(false);
      setLoading(false);
    } catch (error) {
      setError(true);
      setLoading(false);
    }
  };

  const phoneFormats = [
    "123-456-7890",
    "(123) 456-7890",
    "123 456 7890",
    "123.456.7890",
    "+91 (123) 456-7890",
  ];

  return (
    <ProfileInfoContainer>
      <form onSubmit={handleSubmit(handleSaveProfile)}>
        <InputLabel name="name">
          Name*
          <ProfileInput
            type="text"
            name="name"
            {...register("name", { required: true })}
            error={errors.name}
          />
          {errors.name && (
            <InputErrorMessage>
              Please provide a profile name, this will be used for notifications
              addressed to and from you via the Rent Art Stuff system.
            </InputErrorMessage>
          )}
        </InputLabel>
        <InputLabel name="email">
          Email
          <ProfileInput
            type="text"
            name="email"
            disabled
            {...register("email", { required: true })}
            error={errors.email}
          />
        </InputLabel>
        <InputLabel name="number">
          Phone*
          <ProfileInput
            type="number"
            name="phone"
            {...register("phone", {
              required: true,
              pattern: /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
            })}
            error={errors.phone}
          />
          {errors.phone && (
            <InputErrorMessage>
              Must be in one of the following formats:
              {phoneFormats.map((format) => (
                <InputErrorMessage key={format}>{format}</InputErrorMessage>
              ))}
            </InputErrorMessage>
          )}
        </InputLabel>
        <InputLabel name="location">
          Location*
          <ProfileInput
            type="text"
            name="location"
            {...register("location", { required: true })}
            error={errors.location}
          />
          {errors.location && (
            <InputErrorMessage>
              Location may be used as a general location for listings you
              create, Please be specific only to City and state. (e.g. Las
              Vegas, NV)
            </InputErrorMessage>
          )}
        </InputLabel>
        {!loading && !error && (
          <SaveProfileButton type="submit" disabled={!canSave}>
            Save Profile
          </SaveProfileButton>
        )}
        {loading && (
          <LoadingMessage>Saving profile Information...</LoadingMessage>
        )}
        {error && !loading && (
          <ErrorMessage>
            Sorry, something went wrong. Please try again or contact support.
          </ErrorMessage>
        )}
      </form>
    </ProfileInfoContainer>
  );
};

export default ProfileInfo;
