import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { colors } from "theme";
import { CardGroup, NoDataMessage, Tab, TabGroup } from "../styled";
import { RENTAL_TABS } from "../../_static";
import { ENDPOINTS } from "../../../../api/endpoints";
import useSWR from "swr";
import RentalCard from "./RentalCard";
import { navigate } from "@reach/router";

const RentalsContainer = styled.div``;

const BrowseLink = styled.span`
  font-weight: bold;
  color: ${colors.blue};
  cursor: pointer;
  font-size: 20px;
  text-decoration: underline;
`;

const Rentals = ({ user, isMobile }) => {
  const [currentTab, setCurrentTab] = useState("Created");
  const [currentData, setCurrentData] = useState(null);

  const { data: rentals, mutate: refreshRentals } = useSWR(ENDPOINTS.RENTALS);

  useEffect(() => {
    const created = rentals?.filter(
      (request) => request.created_by_user_id === user.internal_user_id
    );

    const received = rentals?.filter(
      (request) => request.created_by_user_id !== user.internal_user_id
    );

    setCurrentData(currentTab === "Created" ? created : received);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTab, rentals]);

  return (
    <RentalsContainer>
      <TabGroup>
        {RENTAL_TABS.map((tab) => (
          <Tab
            key={tab.title}
            onClick={() => setCurrentTab(tab.title)}
            selected={tab.title === currentTab}
          >
            {tab.title}
          </Tab>
        ))}
      </TabGroup>
      <CardGroup isMobile={isMobile}>
        {currentData &&
          currentData.map((rental) => (
            <RentalCard
              key={rental.id}
              created={currentTab === "Created"}
              rental={rental}
              refresh={refreshRentals}
            />
          ))}
      </CardGroup>
      {!currentData ||
        (currentData?.length < 1 && (
          <NoDataMessage>
            Nothing yet,{" "}
            <BrowseLink onClick={() => navigate("/browse")}>
              Browse Listings
            </BrowseLink>{" "}
            to get started!
          </NoDataMessage>
        ))}
    </RentalsContainer>
  );
};

export default Rentals;
