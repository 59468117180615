import React, { useState } from "react";
import { Box, TextField, Checkbox } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { sortAndFilterResults } from "./searchHelpers";
import axios from "axios";
import { colors } from "theme";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 10,
    backgroundColor: colors.lightYellow,
    paddingBottom: 20,
  },
  locationOption: {},
}));
const LocationSearch = (props) => {
  const { defaultValue, handleChange, currentValue } = props;
  const classes = useStyles();
  const [locationOptions, setLocationOptions] = useState([]);
  const [inputValue, setInputValue] = useState(defaultValue);

  // FIX ME -- this will need to be able to be translated to a google map with coordinates possibly
  // so find a way to save the data in a way that can be used by google maps api
  const handleSearch = async (event) => {
    const { value } = event.target;
    setInputValue(value);
    if (value !== "") {
      await axios
        .get(
          `https://api.mapbox.com/geocoding/v5/mapbox.places/${value}.json?access_token=${process.env.REACT_APP_MAPBOX_KEY}`
        )
        .then((resp) => {
          const { features } = resp.data;
          const results = sortAndFilterResults(features);
          setLocationOptions(results);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  const handleCheck = (location) => {
    setInputValue(location);
    handleChange(location);
  };

  return (
    <Box className={classes.container}>
      <Box>
        <Box display="flex" justifyContent="center">
          <TextField
            label="Location"
            variant="outlined"
            autoComplete="false"
            fullWidth
            value={inputValue}
            onChange={handleSearch}
          />
        </Box>
        <Box display="flex" flexDirection="column" textAlign="left">
          {locationOptions.map((location, index) => (
            <Box
              display="flex"
              onClick={() => handleCheck(location)}
              alignItems="center"
            >
              <Checkbox color="primary" checked={currentValue === location} />
              <Box key={location + index} className={classes.locationOption}>
                {location}
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default LocationSearch;
