import { ENDPOINTS } from "../endpoints";
import { createApi } from "../setup";

const useMessagesApi = () => {
  const sendRentalRequest = async (data) => {
    const API = await createApi();

    const resp = await API.post(`${ENDPOINTS.RENTAL_REQUESTS}`, data);
    return resp;
  };

  const sendMessage = async (data) => {
    const API = await createApi();

    const resp = await API.post(`${ENDPOINTS.MESSAGES}`, data);
    return resp;
  };

  const sendQuote = async (data) => {
    const API = await createApi();

    const resp = await API.post(`${ENDPOINTS.QUOTE}`, data);
    return resp;
  };

  const markMessagesAsRead = async (listingId) => {
    const API = await createApi();

    const resp = await API.post(`${ENDPOINTS.MARK_MESSAGES_READ}/${listingId}`);
    return resp;
  };

  return {
    sendRentalRequest,
    sendMessage,
    sendQuote,
    markMessagesAsRead,
  };
};

export default useMessagesApi;
