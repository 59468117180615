import styled from "@emotion/styled";
import { colors } from "theme";

export const TabGroup = styled.div`
  display: flex;
`;

export const Tab = styled.div`
  font-size: 16px;
  font-weight: ${(props) => (props.selected ? "bold" : "")};
  color: ${(props) => (props.selected ? colors.blue : "black")};
  border-bottom: ${(props) =>
    props.selected ? `3px solid ${colors.blue}` : "1px solid #ddd"};
  width: 100px;
  margin-inline: 5px;
  cursor: pointer;
`;

export const CardGroup = styled.div`
  display: grid;
  grid-template-columns: ${(props) =>
    props.isMobile ? "100%" : "25% 25% 25% 25%"};
`;

export const NoDataMessage = styled.div`
  font-size: 20px;
  font-weight: bold;
`;

export const ColorSpan = styled.span`
  color: ${(props) => (props.color ? props.color : "black")};
`;
