import { ENDPOINTS } from "../endpoints";
import { createApi } from "../setup";

const useRentalRequestsApi = () => {
  const sendRentalRequest = async (data) => {
    const API = await createApi();

    const resp = await API.post(`${ENDPOINTS.RENTAL_REQUESTS}`, data);
    return resp;
  };

  const acceptRentalRequest = async (requestId) => {
    const API = await createApi();

    const resp = await API.put(
      `${ENDPOINTS.RENTAL_REQUESTS_ACCEPT}/${requestId}`
    ).then(async () => {
      const resp = await addContactToList(requestId);
      return resp;
    });
    return resp;
  };

  const declineRentalRequest = async (requestId) => {
    const API = await createApi();

    const resp = await API.put(
      `${ENDPOINTS.RENTAL_REQUESTS_DECLINE}/${requestId}`
    );
    return resp;
  };

  const deleteRentalRequest = async (requestId) => {
    const API = await createApi();

    const resp = await API.delete(`${ENDPOINTS.RENTAL_REQUESTS}/${requestId}`);
    return resp;
  };

  const endRentalRequest = async (requestId) => {
    const API = await createApi();

    const resp = await API.post(`${ENDPOINTS.RENTAL_REQUESTS}/cancel/${requestId}`);
    return resp;
  };

  const addContactToList = async (requestId) => {
    const API = await createApi();
    const data = { rental_request_origin_id: requestId };
    const resp = await API.post(ENDPOINTS.CONTACTS, data);
    return resp;
  };

  return {
    sendRentalRequest,
    acceptRentalRequest,
    declineRentalRequest,
    deleteRentalRequest,
    endRentalRequest
  };
};

export default useRentalRequestsApi;
