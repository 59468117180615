import { ENDPOINTS } from "../endpoints";
import { createApi } from "../setup";

const useSendFeedbackApi = () => {
  const sendMessage = async (message) => {
    const API = await createApi();
    const data = {
      content: message,
    };
    const savedListing = await API.post(`${ENDPOINTS.USER_FEEDBACK}`, data)
      .then((resp) => {
        return resp.data;
      })
      .catch((e) => {
        return "error";
      });
    return savedListing;
  };

  return {
    sendMessage,
  };
};

export default useSendFeedbackApi;
