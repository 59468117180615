import React from "react";
import styled from "@emotion/styled";
import { DASHBOARD_MENU_ITEMS } from "./_static";

const MobileMenuContainer = styled.div`
  width: 100%;
  padding: 10px;
  position: relative;
`;

const MenuSelect = styled.select`
  width: 100%;
  height: 50px;
  padding-inline: 5px;
  font-size: 18px;
`;

const MenuOption = styled.option`
  font-size: 18px;
  width: 100%;
`;

const MobileMenu = ({ activeTab, setActiveTab }) => {
  return (
    <MobileMenuContainer>
      <MenuSelect onChange={(e) => setActiveTab(e.target.value)}>
        {DASHBOARD_MENU_ITEMS.map((item) => (
          <MenuOption key={item} value={item}>
            {item}
          </MenuOption>
        ))}
      </MenuSelect>
    </MobileMenuContainer>
  );
};

export default MobileMenu;
