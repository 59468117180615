import React from "react";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  container: {
    marginBottom: 10,
    display: "flex",
    flexDirection: "column",
  },
  label: {
    textAlign: "left",
    fontWeight: "bold",
    display: "flex",
    paddingBottom: 5,
  },
  errorText: {
    color: "red",
    paddingLeft: 5,
  },
}));

const CreateListingInput = (props) => {
  const {
    type = "text",
    defaultValue,
    value,
    title,
    onChange,
    readOnly,
    label,
    required,
    error,
    errorText,
    register,
    name,
    ...restProps
  } = props;
  const classes = useStyles();
  return (
    <Box className={classes.container}>
      <Box className={classes.label}>
        {label}
        {required ? "* " : " "}
        {error && <Box className={classes.errorText}>{errorText}</Box>}
      </Box>
      <input
        type={type}
        style={{
          maxWidth: 500,
          borderColor: error ? "red" : "",
          outline: "none",
          padding: 5,
        }}
        defaultValue={defaultValue}
        value={value}
        disabled={readOnly}
        title={title}
        variant="outlined"
        label={title}
        onChange={(e) => onChange(e.target.value)}
        {...register(name, { required: true })}
        {...restProps}
      />
    </Box>
  );
};

export default CreateListingInput;
