import React, { useState } from "react";
import { Box, Modal } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import CommonButton from "../../../components/CommonButton";
import useImageAssetsApi from "../../../api/image-assets";

const useStyles = makeStyles((theme) => ({
  container: {
    borderLeft: "1px solid black",
    borderRight: "1px solid black",
    borderBottom: "1px solid black",
    minHeight: "100px",
    textAlign: "center",
    padding: 10,
    backgroundColor: theme.palette.primary.mainBackgroundColor,
  },
  modal: {
    overflow: "scroll",
  },
  editTitle: {
    fontSize: 25,
  },
  images: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
  },
  imgCard: {
    padding: 15,
    margin: 10,
    position: "relative",
    backgroundColor: "#333",
  },
  cardX: {
    position: "absolute",
    top: -8,
    right: -8,
    color: "white",
    backgroundColor: "red",
    borderRadius: "50%",
    textAlign: "center",
    width: 18,
    padding: 3,
    cursor: "pointer",
  },
  img: {},

  // ========

  buttonGroup: {
    display: "flex",
    width: "fit-content",
    padding: 10,
    margin: "auto",
  },
  button: {
    margin: 5,
  },
}));

const EditImagesModal = (props) => {
  const { open, assets = [], handleClose, refresh } = props;
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [assetToDelete, setAssetToDelete] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const { removeImageAsset } = useImageAssetsApi();
  const atLeastOne = assets.length > 0;
  const classes = useStyles();

  const handleDelete = (asset) => {
    setAssetToDelete(asset);
    setIsConfirmModalOpen(true);
  };
  const deleteAsset = async () => {
    setLoading(true);
    await removeImageAsset(assetToDelete.id)
      .then((resp) => {
        setLoading(false);
        setSuccess(true);
        setError(false);
        refresh();
      })
      .catch(() => {
        setLoading(false);
        setError(true);
        setSuccess(false);
        refresh();
      });
  };
  const resetState = () => {
    setLoading(false);
    setError(false);
    setSuccess(false);
  };
  return (
    <Modal open={open} onClose={handleClose} className={classes.modal}>
      <Box className={classes.container}>
        {!atLeastOne && <Box>No Images</Box>}
        {atLeastOne && (
          <>
            <Box className={classes.editTitle}>Edit Images</Box>
            <Box className={classes.images}>
              {assets.map((asset) => (
                <Box className={classes.imgCard}>
                  <Box
                    className={classes.cardX}
                    onClick={() => handleDelete(asset)}
                  >
                    X
                  </Box>
                  <Box
                    component="img"
                    src={asset.asset_uri}
                    width="80px"
                    height="80px"
                    className={classes.img}
                  />
                </Box>
              ))}
            </Box>
          </>
        )}
        <Box className={classes.buttonGroup}>
          <CommonButton
            text={"Close"}
            isUppercase
            buttonVariant="normal"
            handleClick={() => {
              setAssetToDelete(undefined);
              resetState();
              handleClose();
            }}
          />
        </Box>
        <Modal open={isConfirmModalOpen}>
          <Box className={classes.container}>
            {!success && !loading && (
              <Box>
                <Box>Are You Sure?</Box>
                <Box>This will permanently delete the image.</Box>
              </Box>
            )}
            {!success && !loading && assetToDelete && (
              <Box
                component="img"
                src={assetToDelete.asset_uri}
                width="100px"
                height="100px"
              />
            )}
            {loading && <Box>Uploading now...</Box>}
            {error && <Box>Something went wrong.</Box>}
            {success && <Box>Success!</Box>}
            <Box className={classes.buttonGroup}>
              {!loading && !success && (
                <Box className={classes.button}>
                  <CommonButton
                    text="Delete"
                    handleClick={() => deleteAsset()}
                    isUppercase
                  />
                </Box>
              )}
              <Box className={classes.button}>
                <CommonButton
                  text={success ? "Ok" : " Cancel"}
                  isUppercase
                  buttonVariant="normal"
                  handleClick={() => {
                    setIsConfirmModalOpen(false);
                    setAssetToDelete(undefined);
                    resetState();
                  }}
                />
              </Box>
            </Box>
          </Box>
        </Modal>
      </Box>
    </Modal>
  );
};

export default EditImagesModal;
