import React from "react";
import { Box, Modal } from "@mui/material";
import { colors } from "../../theme";

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  modalContent: {
    backgroundColor: colors.offWhite,
    margin: "auto",
    padding: 10,
  },
  modal: {
    overflow: "auto",
  },
}));

const CommonModal = (props) => {
  const { open, fullScreen } = props;
  const classes = useStyles();
  return (
    <Modal open={open} fullScreen={fullScreen} className={classes.modal}>
      <Box className={classes.modalContent}>{props.children}</Box>
    </Modal>
  );
};

export default CommonModal;
