import React, { useState } from "react";
import { Grid } from "@mui/material";
import { navigate } from "@reach/router";
import useListingsApi from "../../api/listings";

import {
  CardContainer,
  CardImage,
  Title,
  Category,
  Price,
  CardButton,
  ButtonContainer,
  CardError,
} from "./styled";

const ListingCard = ({
  listing,
  owner = false,
  refresh = () => {},
  showButtons = false,
}) => {
  const [error, setError] = useState(null);
  const { deleteListing } = useListingsApi();

  const titleText =
    listing.name.length >= 23
      ? `${listing.name.slice(0, 23)}...`
      : listing.name;

  const editLink = `/edit/${listing.id}`;

  const viewLink = `/listings/single/${listing.id}`;

  const deleteListingAction = async () => {
    await deleteListing(listing.id).catch((err) => setError(err));
    refresh();
  };

  return (
    <Grid item xs={12} md={3}>
      <CardContainer>
        <CardImage
          src={listing.assets[0].asset_uri}
          onClick={() => navigate(viewLink)}
        />
        <Title>{titleText}</Title>
        <Category>{listing.primary_category}</Category>
        <Price>
          ${listing.unit_price} / {listing.unit_time}
        </Price>
        {showButtons && (
          <ButtonContainer>
            {owner && (
              <CardButton onClick={() => navigate(editLink)}>Edit</CardButton>
            )}
            {owner && (
              <CardButton onClick={deleteListingAction}>Delete</CardButton>
            )}
          </ButtonContainer>
        )}
        {error && <CardError>{error.message}</CardError>}
      </CardContainer>
    </Grid>
  );
};

export default ListingCard;
