import { atom, useRecoilState } from "recoil";

const categoryState = atom({
  key: "categoryState",
  default: [],
});

const primaryCategoryState = atom({
  key: "primaryCategoryState",
  default: "",
});

const listingNameState = atom({
  key: "listingNameState",
  default: "",
});

const listingDescriptionState = atom({
  key: "listingDescriptionState",
  default: "",
});

const listingTagsState = atom({
  key: "listingTagsState",
  default: [],
});

const useStep1State = () => {
  const [primaryCategory, setPrimaryCategory] =
    useRecoilState(primaryCategoryState);
  const [categories, setCategories] = useRecoilState(categoryState);
  const [listingName, setListingName] = useRecoilState(listingNameState);
  const [listingDescription, setListingDescription] = useRecoilState(
    listingDescriptionState
  );
  const [listingTags, setListingTags] = useRecoilState(listingTagsState);

  const handleCategoryChange = (value) => {
    const found = categories.includes(value);

    if (found) {
      const newSet = categories.filter((e) => e !== value);
      setCategories(newSet);
    } else {
      setCategories([...categories, value]);
    }
  };

  const handlePrimaryCategoryChange = (value) => {
    setPrimaryCategory(value);
  };

  const handleNameChange = (value) => {
    setListingName(value);
  };

  const handleDescriptionChange = (value) => {
    setListingDescription(value);
  };

  const handleTagsChange = (value) => {
    const afterAdding = [...listingTags, value];
    setListingTags(afterAdding);
  };
  const handleRemoveTag = (value) => {
    const afterRemoval = listingTags.filter((tag) => tag !== value);
    setListingTags(afterRemoval);
  };

  const clearStep1 = () => {
    setCategories([]);
    setPrimaryCategory("");
    setListingName("");
    setListingDescription("");
  };
  return {
    categories,
    listingName,
    listingDescription,
    primaryCategory,
    listingTags,
    setCategories,
    handleCategoryChange,
    handleNameChange,
    handleDescriptionChange,
    handlePrimaryCategoryChange,
    handleTagsChange,
    handleRemoveTag,
    clearStep1,
  };
};

export { useStep1State };
