import { atom, useRecoilState } from "recoil";

const listingImagesState = atom({
  key: "listingImagesState",
  default: [],
});

const useStep4State = () => {
  const [listingImages, setListingImages] = useRecoilState(listingImagesState);

  const handleListingImagesChange = (value) => {
    setListingImages(value);
  };

  const clearStep4 = () => {
    setListingImages([]);
  };

  return {
    listingImages,
    setListingImages,
    handleListingImagesChange,
    clearStep4,
  };
};

export { useStep4State };
