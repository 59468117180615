import { useStep1State } from "./createListingStates/Step1";
import { useStep2State } from "./createListingStates/Step2";
import { useStep3State } from "./createListingStates/Step3";
import { useStep4State } from "./createListingStates/Step4";
import { useStep5State } from "./createListingStates/Step5";
import { useEditListingState } from "./editListingStates";

const createListingStates = {
  useStep1State,
  useStep2State,
  useStep3State,
  useStep4State,
  useStep5State,
};

export { createListingStates, useEditListingState };
