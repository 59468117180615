import React from "react";
import { Button } from "@mui/material";
import { colors } from "../../theme";

import makeStyles from '@mui/styles/makeStyles';

const getVariantTheme = (variant, theme) => {
  switch (variant) {
    case "normal":
      return {
        backgroundColor: colors.blue,
        border: "none",
        "&:hover": {
          cursor: "pointer",
          backgroundColor: colors.blueHover,
        },
      };
    case "coolBlue":
      return {
        background: colors.coolBlue,
        border: "none",
        "&:hover": {
          cursor: "pointer",
          backgroundColor: colors.coolBlueHover,
        },
      };
    case "red":
      return {
        backgroundColor: colors.red,
        border: "none",
        "&:hover": {
          cursor: "pointer",
          backgroundColor: colors.red,
        },
        "&:disabled": {
          backgroundColor: colors.disabledBackground,
          color: colors.disabledText,
        },
      };
    default:
      return {
        backgroundColor: theme.palette.primary.main,
        border: "none",
        "&:hover": {
          cursor: "pointer",
          backgroundColor: theme.palette.primary.hover,
        },
      };
  }
};

const useStyles = ({
  isFullWidth,
  isUppercase,
  buttonVariant,
  customStyles,
}) => {
  return makeStyles((theme) => {
    const btnTheme = getVariantTheme(buttonVariant, theme);
    return {
      btn: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: isFullWidth ? "100%" : "auto",
        height: 50,
        borderRadius: 10,
        fontSize: "14px",
        fontWeight: 700,
        color: "#fff",
        padding: "0 20px",
        outline: "none",
        fontFamily: `${theme.typography.h2.fontFamily}`,
        textTransform: isUppercase ? "uppercase" : "none",
        "&:hover": {
          cursor: "pointer",
        },
        "&:disabled": {
          opacity: 0.3,
          background: "#000",
          cursor: "default",
          border: "solid 1px #ffffff",
          color: "#ccc",
        },
        ...btnTheme,
        // ...customStyles(theme),
      },
    };
  })();
};

const CommonButton = (props) => {
  const {
    text,
    customStyles,
    handleClick,
    buttonVariant,
    isUppercase,
    isFullWidth,
    disabled,
  } = props;
  const classes = useStyles({
    customStyles,
    buttonVariant,
    isUppercase,
    isFullWidth,
  });
  return (
    <Button
      onClick={handleClick}
      variant="contained"
      className={classes.btn}
      disabled={disabled}
    >
      {text}
    </Button>
  );
};

export default CommonButton;
